import React from "react";
import PropTypes from "prop-types";

const LoginHeader = (props) => {
  return (
    <div className="bg-gradient-to-r from-green-brand to-blue-mysterious">
      <div className="container mx-auto flex flex-col">
        <div className="flex flex-row items-center justify-center p-6">
          <div className={` ${props.logoText && 'pr-6 md:border-r'}`}>
            <img className="h-16" src={props.logo} alt="" />
          </div>
          {props.logoText && <div className="ml-6">
            {props.logoText && <img className="h-12" src={props.logoText} alt="" />}
          </div>}
        </div>
        <div className="h-12"></div>
      </div>
    </div>
  );
};

LoginHeader.propTypes = {
  logo: PropTypes.any,
  logoText: PropTypes.any,
};

export default LoginHeader;
