import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import MapDetail from "./MapDetail";
import PropTypes from "prop-types";
import { deviceModelPulsingPin } from './MarkerIcon'


const SchedulerMap = (props) => {

  const getIcon = (type, model) => {
    return deviceModelPulsingPin({ alive: type, model })
  }

  return (
    <div className=" relative w-3/4 rounded-md overflow-hidden">
      <div className=" absolute top-3 left-12 bg-white rounded-md px-2 py-1 text-xs flex gap-2 flex-col shadow-sm" style={{ zIndex: 999 }}>
        <div className="flex items-center gap-1">
          <div className=" fill-current text-yellow-400 h-3 w-3">
            <svg viewBox="0 0 140 123" xmlns="http://www.w3.org/2000/svg">
              <path d="M63.0718 3.99999C66.151 -1.33334 73.849 -1.33333 76.9282 4L138.416 110.5C141.495 115.833 137.646 122.5 131.488 122.5H8.51218C2.35378 122.5 -1.49521 115.833 1.584 110.5L63.0718 3.99999Z" />
            </svg>
          </div>
          <div className=" text-xs">BL</div>
        </div>
        <div className="flex items-center gap-1">
          <div className=" fill-current text-blue-500 h-3 w-3">
            <svg viewBox="0 0 125 125" xmlns="http://www.w3.org/2000/svg">
              <rect width="125" height="125" rx="8" />
            </svg>
          </div>
          <div className=" text-xs">EL</div>
        </div>
        <div className="flex items-center gap-1">
          <div className=" fill-current text-blue-500 h-3 w-3">
            <svg viewBox="0 0 126 126" xmlns="http://www.w3.org/2000/svg">
              <circle cx="63" cy="63" r="63" />
            </svg>
          </div>
          <div className=" text-xs">LT</div>
        </div>
      </div>
      <MapContainer center={[0, 0]} zoom={props.mapZoom} scrollWheelZoom={true}>
        <MapDetail mapCenter={props.mapCenter} />
        <TileLayer attribution={props.attribution} url={props.baseMapUrl} maxNativeZoom={17} maxZoom={100} />
        {props.pins && props.pins.map(p => <Marker position={p.pin} icon={getIcon(p.type, p.model)}>
          <Popup>
            {p.name}
          </Popup>
        </Marker>)}
      </MapContainer>
    </div>
  );
};

SchedulerMap.propTypes = {
  mapCenter: PropTypes.object,
  attribution: PropTypes.string,
  baseMapUrl: PropTypes.string,
  mapZoom: PropTypes.number,
  pins: PropTypes.array
};

export default SchedulerMap;
