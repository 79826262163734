import { useEffect, useState } from "react";
import { Input, Transfer, notification, Button, Tabs, Tag } from "antd";
import {
    SearchOutlined,
    TagOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { transformTurkish } from "../utils/RegExUtility"
import tr from "../i18n"
import { useQuery, useQueryClient } from "react-query"
import InfoLabel from "../components/InfoLable";


const { TabPane } = Tabs;
const { CheckableTag } = Tag;


const Tags = () => {
    const [tags, setTags] = useState([]);
    const [tagName, setTagName] = useState(null)
    const [selectedTags, setSelectedTags] = useState([])
    const [targetKeys, setTargetKeys] = useState([])
    const [tagSearchText, setTagSearchText] = useState(null)
    const [loadingGardensTransfer, setLoadingGardensTransfer] = useState(false)

    const queryClient = useQueryClient()
    const gardens = useQuery(['GetGardens'], () => {
        return axios.get(process.env.REACT_APP_PLANNER_API_END_POINT + '/garden?includeDisabled=true')
    })

    const tagsResult = useQuery(['GetTags'], () => {
        return axios.get(process.env.REACT_APP_PLANNER_API_END_POINT + '/tag')
    })

    useEffect(() => {
        if (!tagsResult.data) return;

        setTags(tagsResult.data.data.tags)
    }, [tagsResult.data])

    useEffect(() => {
        const fetchAllGardens = async () => {
            if (selectedTags.length === 0) return;

            try {
                setLoadingGardensTransfer(true)
                const res = await axios.get(
                    process.env.REACT_APP_PLANNER_API_END_POINT + `/garden?tags=${selectedTags.map(t => t.id).join(',')}&includeDisabled=true`
                )
                console.log(res);
                setTargetKeys(res.data.gardens.map(g => g.id))
            } catch (error) {
                console.log(error);
            } finally {
                setLoadingGardensTransfer(false)
            }
        }

        fetchAllGardens()
    }, [selectedTags])

    const onTagNameChange = (e) => {
        setTagName(e.target.value)
    }

    const onAddTag = async () => {
        try {
            const res = await axios.post(process.env.REACT_APP_PLANNER_API_END_POINT + '/tag', {
                name: tagName,
                resource: 'gardens'
            })

            if (res.data.success) {
                notification.success({
                    message: tr(`Tag created successfully`),
                    description: `gardens: ${tagName}`,
                    placement: 'topRight'
                })

                setTags(prev => [...prev, res.data.tag])
                setTagName(null)
            } else {
                notification.error({
                    message: tr('Something went wrong'),
                    description: res.data,
                    placement: 'topRight'
                })
            }
        } catch (error) {
            notification.error({
                message: tr('Something went wrong'),
                description: error.message,
                placement: 'topRight'
            })
        }

    }

    const onTagSelection = (tag, checked) => {
        if (checked) {
            setSelectedTags(prev => [...prev, tag])
        } else {
            setSelectedTags(selectedTags.filter(t => t.id !== tag.id))
        }
    }

    const isChecked = (tag) => {
        const index = selectedTags.findIndex(t => t.id === tag.id)
        return index !== -1
    }

    const filterOption = (input, item) => {
        console.log(input, item);
        return RegExp(`.*${transformTurkish(input)}.*`, "gi").test(
            transformTurkish(item.geoJson.properties.MAHAL_ADI))
    }

    const onTargetsChange = async (nextTargetKeys, direction, moveKeys) => {
        console.log(nextTargetKeys, direction, moveKeys);
        const tagIds = selectedTags.map(t => t.id)
        if (direction === 'right') {
            // adding gardens : moveKeys
            try {
                const res = await axios.put(`${process.env.REACT_APP_PLANNER_API_END_POINT}/garden/tags`, {
                    tagIds,
                    gardenIds: moveKeys
                })
                console.log(res);

                if (res.data.success) {
                    notification.success({
                        message: tr("Gardens assigned successfully"),
                        description: `Seçilen etikete ${res.data.gardens.nModified} bahçe eklendi`,
                        placement: "topRight",
                    });

                    // if successful set target keys
                    setTargetKeys(nextTargetKeys)
                    queryClient.invalidateQueries(['GetGardens'])
                } else {
                    notification.error({
                        message: tr("Something went wrong"),
                        description: tr(`While assigning gardens`),
                        placement: "topRight",
                    });
                }
            } catch (error) {
                console.error(error);
            }
        }

        if (direction === 'left') {
            // removing gardens : moveKeys

            try {
                const res = await axios.delete(`${process.env.REACT_APP_PLANNER_API_END_POINT}/garden/tags`, {
                    data: {
                        tagIds,
                        gardenIds: moveKeys
                    }
                })

                if (res.data.success) {
                    notification.success({
                        message: tr("Gardens removal successful"),
                        description: `${res.data.gardens.nModified} ${tr('gardens removed from  selected tags')}`,
                        placement: "topRight",
                    });

                    // if successful set target keys
                    setTargetKeys(nextTargetKeys)
                    queryClient.invalidateQueries(['GetGardens'])
                } else {
                    notification.error({
                        message: tr("Something went wrong"),
                        description: tr(`While removing gardens`),
                        placement: "topRight",
                    });
                }
            } catch (error) {
                console.error(error);
            }
        }

    };

    const getFilteredTags = () => {
        if (tagSearchText && tagSearchText.trim() !== "") {
            return tags.filter(t => RegExp(`.*${transformTurkish(tagSearchText.trim())}.*`, "gi").test(
                transformTurkish(t.name)))
        }

        return tags;
    }

    const onTagSearch = (e) => {
        setTagSearchText(e.target.value)
    }

    // implementation of onRemoveSelectedTags
    const onRemoveSelectedTags = async () => {
        try {
            const res = await axios.delete(`${process.env.REACT_APP_PLANNER_API_END_POINT}/tag`, {
                data: {
                    ids: selectedTags.map(t => t.id),
                }
            })

            if (res.data.success) {

                console.log(res.data);
                notification.success({
                    message: tr("Tag removal successful"),
                    description: `${res.data.data.deletedCount} ${tr('tags removed')}`,
                    placement: "topRight",
                });

                // if successful set target keys
                setTargetKeys([])
                setSelectedTags([])
                queryClient.invalidateQueries(['GetGardens'])
                queryClient.invalidateQueries(['GetTags'])
            }
        } catch (error) {
            let message = error.message;
            if (error.response && error.response.data && error.response.data.error) {
                message = error.response.data.error
            }
            notification.error({
                message: tr("Something went wrong"),
                description: message,
                placement: "topRight",
            });
        }
    }



    return <>
        <div className="bg-white border text-gray-800 rounded-md flex flex-col rounded-tl-none">

            <div className="w-full p-4">

                <Tabs defaultActiveKey="1">
                    <TabPane tab="Bahçeler" key="1" >
                        <div className="w-full flex flex-row gap-5">
                            <div className="w-1/4 flex flex-col">
                                <div className="w-full border-b pb-4">
                                    <span className="block font-bold">Etiket yaratın</span>
                                    <InfoLabel> Bahçeler için etiket yaratabilirsiniz.</InfoLabel>
                                    <div className="flex flex-row gap-2 mt-2">
                                        <Input style={{ width: 200, borderRadius: 5 }} prefix={<TagOutlined />} placeholder="Etiket" onChange={onTagNameChange} value={tagName} />
                                        <Button style={{ borderRadius: 5 }} onClick={onAddTag}>+ Ekle</Button>
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <div className="flex flex-row gap-2 mt-4">
                                        <Input style={{ borderRadius: 5 }} prefix={<SearchOutlined />} placeholder="Bahçelere ait etiketlerden ara" onChange={onTagSearch} allowClear />
                                    </div>
                                    <div className="mt-5">
                                        {
                                            tagsResult.isLoading && <div className="flex gap-3 flex-wrap animate-pulse">
                                                <div className="h-5 w-20 bg-gray-200 rounded-sm"></div>
                                                <div className="h-5 w-10 bg-gray-200 rounded-sm"></div>
                                                <div className="h-5 w-20 bg-gray-200 rounded-sm"></div>
                                                <div className="h-5 w-5 bg-gray-200 rounded-sm"></div>
                                                <div className="h-5 w-20 bg-gray-200 rounded-sm"></div>
                                                <div className="h-5 w-20 bg-gray-200 rounded-sm"></div>
                                                <div className="h-5 w-5 bg-gray-200 rounded-sm"></div>
                                                <div className="h-5 w-20 bg-gray-200 rounded-sm"></div>
                                                <div className="h-5 w-10 bg-gray-200 rounded-sm"></div>
                                            </div>
                                        }
                                        {!tagsResult.isLoading && getFilteredTags().map(t => <CheckableTag onChange={c => onTagSelection(t, c)} checked={isChecked(t)}>
                                            <div className="flex flex-col">
                                                <div>{t.name}</div>
                                            </div>
                                        </CheckableTag>)}
                                    </div>
                                </div>
                            </div>
                            <div className="w-3/4 flex flex-col">
                                <div className="w-full pb-4 flex">
                                    <div>
                                        {selectedTags.length > 0 && <span className="block font-bold text-blue-600">{selectedTags.length} Etiket seçildi</span>}
                                        <span className="block font-bold">Bahçe etiketleme</span>
                                        <InfoLabel>Seçili etikete bahçe ekleyebilirsiniz.</InfoLabel>
                                    </div>
                                    {/* button to delete selected tags */}
                                    <div className="flex flex-row gap-2 ml-auto">
                                        <Button style={{ borderRadius: 5 }} onClick={onRemoveSelectedTags} disabled={selectedTags.length === 0}>Seçili etiketleri sil</Button>
                                    </div>
                                </div>
                                <div>
                                    {gardens.isSuccess && <Transfer
                                        dataSource={gardens.data.data.gardens}
                                        showSearch
                                        disabled={loadingGardensTransfer || selectedTags.length === 0}
                                        filterOption={filterOption}
                                        targetKeys={targetKeys}
                                        onChange={onTargetsChange}
                                        render={(item) => item.geoJson.properties.MAHAL_ADI}
                                        rowKey={(r) => r.id}
                                        listStyle={{ width: 400, height: 600 }}
                                        locale={{ itemUnit: 'adet', itemsUnit: 'adet', searchPlaceholder: 'Arama' }}
                                        titles={[
                                            <div className="text-gray-800 font-bold text-xs">
                                                Seçilebilir
                                                <span className="text-gray-500 text-xs block font-normal">
                                                    Assignable Gardens
                                                </span>
                                            </div>,
                                            <div className="text-gray-800 font-bold text-xs">
                                                Atanmış
                                                <span className="text-gray-500 text-xs block font-normal">
                                                    Gardens assigned to the tag
                                                </span>
                                            </div>,
                                        ]}
                                    />}
                                </div>
                            </div>

                        </div>

                    </TabPane>
                    <TabPane tab="Cihazlar" key="2" disabled>
                        Content of Tab Pane 2
                    </TabPane>
                    <TabPane tab="Kullanıcılar" key="3" disabled>
                        Content of Tab Pane 3
                    </TabPane>
                </Tabs>
            </div>
        </div>
    </>
}

export default Tags