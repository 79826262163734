import axios from "axios";

export const LoginService = {
  login(payload) {
    return axios.post(
      `${process.env.REACT_APP_GARDENER_API_END_POINT}/auth/login`,
      payload
    );
  },
  verify(payload) {
    return axios.post(
      `${process.env.REACT_APP_GARDENER_API_END_POINT}/auth/verify`,
      payload
    );
  },
};
