import { DurationMapper } from "./DurationMapper";

export const WateringMapper = (args) => {
  return `${
    DurationMapper({
      start: args.source.data.start,
      end: args.source.data.end,
      totalSubDeviceLength: 8,
    })[-1]
  }  dakika sulama`;
};
