import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { AppContext } from "../AppContext";
import { useQuery } from "react-query";

const Dashboard = () => {
  const { gardens, fetchAllGardens } = useContext(AppContext);

  const dashboard = useQuery("Dashboard", () =>
    axios.get(
      process.env.REACT_APP_PLANNER_API_END_POINT +
      "/garden/dashboard/" +
      process.env.REACT_APP_DASHBOARD
    )
    , { refetchOnWindowFocus: false });

  const wateringDashboard = useQuery("WateringDashboard", () =>
    axios.post(
      process.env.REACT_APP_PLANNER_API_END_POINT +
      "/garden/dashboard/",
      {
        id: process.env.REACT_APP_TENANT_WATERING_DASHBOARD,
        params: {
          "relative_date": "yesterday",
          "relative_date_1": "past7days",
          "relative_date_2": "past30days"
        }
      }
    )
    , { refetchOnWindowFocus: false });

  const [wateringReportActive, setWateringReportActive] = useState(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const wateringReport = localStorage.getItem('wateringReportActive')
    setWateringReportActive(!!wateringReport)

    fetchAllGardens()
  }, [])

  const [selectedGarden, setSelectedGarden] = useState(null)

  const GardenReportY = useQuery(["GardenReportY", selectedGarden], () =>
    axios.get(
      process.env.REACT_APP_PLANNER_API_END_POINT +
      `/garden/report?sV=1&sU=day&eV=1&eU=day&gardenId=${selectedGarden.id}`
    )
  );

  const GardenReport7D = useQuery(["GardenReport7D", selectedGarden], () =>
    axios.get(
      process.env.REACT_APP_PLANNER_API_END_POINT +
      `/garden/report?sV=7&sU=day&eV=1&eU=day&gardenId=${selectedGarden.id}`
    )
  );

  const GardenReport30D = useQuery(["GardenReport30D", selectedGarden], () =>
    axios.get(
      process.env.REACT_APP_PLANNER_API_END_POINT +
      `/garden/report?sV=30&sU=day&eV=1&eU=day&gardenId=${selectedGarden.id}`
    )
  );

  return (
    <>
      <div className="p-4 bg-white bg-opacity-20 rounded-md text-white flex flex-col gap-4">
        {wateringReportActive && <div className="rounded-md bg-white  text-gray-700">
          <div className="flex border rounded-md h-96 overflow-hidden">
            <div className="flex flex-col overflow-y-auto">
              {gardens.map(g => <div className="p-4 hover:bg-gray-100 cursor-pointer font-bold" onClick={() => setSelectedGarden(g)}>{g.name}</div>)}
            </div>
            <div className="flex flex-col w-full" >
              <div className="p-4 font-bold">{(selectedGarden && selectedGarden.name) || 'Select a garden'}</div>

              {selectedGarden && <div className="flex flex-col bg-gray-100 w-full  h-full overflow-y-auto">
                {/* Yesterday */}
                <div className="flex flex-col px-4 pt-4 gap-2">
                  <div className=" text-xs text-gray-500">Yesterday</div>
                  <div>
                    <div className=" flex gap-4 flex-wrap">
                      {GardenReportY.isSuccess && GardenReportY.data.data.data.map(r => <StatCard

                        flow={r.totalWatering.flow * (r.device.waterMeter?.status === 'active' ? r.device.waterMeter.tickValue : 1)}
                        duration={r.totalWatering.duration}
                        waterings={r.waterings}
                        device={r.device} />)}
                      {GardenReportY.isSuccess && GardenReportY.data.data.data.length === 0 && <div className="bg-white rounded-md font-bold p-4">Not found.</div>}
                      {GardenReportY.isLoading && <div className="bg-white rounded-md font-bold p-4">Loading...</div>}
                    </div>
                  </div>
                </div>
                {/* 7 days */}
                <div className="flex flex-col px-4 pt-4 gap-2">
                  <div className=" text-xs text-gray-500 ">Last 7 days</div>
                  <div>
                    <div className=" flex gap-4 flex-wrap">
                      {GardenReport7D.isSuccess && GardenReport7D.data.data.data.map(r => <StatCard

                        flow={r.totalWatering.flow * (r.device.waterMeter?.status === 'active' ? r.device.waterMeter.tickValue : 1)}
                        duration={r.totalWatering.duration}
                        waterings={r.waterings}
                        device={r.device} />)}
                      {GardenReport7D.isSuccess && GardenReport7D.data.data.data.length === 0 && <div className="bg-white rounded-md font-bold p-4">Not found.</div>}
                      {GardenReport7D.isLoading && <div className="bg-white rounded-md font-bold p-4">Loading...</div>}

                    </div>
                  </div>
                </div>
                {/* 30 days */}
                <div className="flex flex-col px-4 pt-4 gap-2">
                  <div className=" text-xs text-gray-500 ">Last 30 days</div>
                  <div>
                    <div className=" flex gap-4 flex-wrap">
                      {GardenReport30D.isSuccess && GardenReport30D.data.data.data.map(r => <StatCard

                        flow={r.totalWatering.flow * (r.device.waterMeter?.status === 'active' ? r.device.waterMeter.tickValue : 1)}
                        duration={r.totalWatering.duration}
                        waterings={r.waterings}
                        device={r.device} />)}
                      {GardenReport30D.isSuccess && GardenReport30D.data.data.data.length === 0 && <div className="bg-white rounded-md font-bold p-4">Not found.</div>}
                      {GardenReport30D.isLoading && <div className="bg-white rounded-md font-bold p-4">Loading...</div>}
                    </div>
                  </div>
                </div>
              </div>}
            </div>


          </div>
        </div>}
        <div className="rounded-md overflow-hidden" style={{ height: 1800 }}>
          {process.env.REACT_APP_TENANT_WATERING_DASHBOARD !== "0" && wateringDashboard.data && <iframe
            title="Watering Dashboard"
            src={wateringDashboard.data.data.url}
            frameborder="0"
            width="100%"
            height="35%"
            allowtransparency
          ></iframe>}
          {dashboard.data && <iframe
            title="Dashboard"
            src={dashboard.data.data.url}
            frameborder="0"
            width="100%"
            height="65%"
            allowtransparency
          ></iframe>}
        </div>
      </div>
    </>
  );
};

const getTime = (s) => [(s - (s % 60)) / 60, s % 60]

const StatCard = ({ device, flow, duration, waterings }) => <div className="flex flex-col">
  <div className="py-1">{device.name}</div>
  <div className="flex flex-col">
    <div className="flex bg-white rounded-t-md border-b text-3xl font-bold text-blue-500">

      <div className=" border-r p-4">{Number((flow / 1000).toFixed(3))}<div className=" text-xs text-gray-500"> tons</div></div>
      <div className="p-4 flex gap-2">
        {getTime(duration)[0] > 0 && <div className="flex flex-col items-center">{getTime(duration)[0]} <div className=" text-xs text-gray-500">hours</div> </div>}
        {<div className="flex flex-col items-center">{getTime(duration)[1]} <div className=" text-xs text-gray-500">mins</div> </div>}
        {/* <div className="flex flex-col items-center">{getTime(duration)[2]} <div className=" text-xs text-gray-500">secs</div> </div> */}
      </div>
    </div>
    <div className=" bg-white rounded-b-md grid grid-cols-5 gap-2 p-2 px-4">
      {waterings.length > 0 && waterings.map(w => (device.valves[w._id.valve.toString()].state !== 'off' && <>
        <div className=" col-span-3">{device.valves[w._id.valve.toString()].name}</div>
        <div className="flex  items-center">{Number(((w.flow * (device.waterMeter?.status === 'active' ? device.waterMeter.tickValue : 1)) / 1000).toFixed(3))} <div className=" text-xs text-gray-500">T</div></div>
        <div><div className="flex gap-1">
          {getTime(w.duration)[0] > 0 && <div className="flex  items-center">{getTime(w.duration)[0]} <div className=" text-xs text-gray-500">h</div> </div>}
          {<div className="flex  items-center">{getTime(w.duration)[1]} <div className=" text-xs text-gray-500">m</div> </div>}
          {/* <div className="flex  items-center">{getTime(w.duration)[2]} <div className=" text-xs text-gray-500">s</div> </div> */}
        </div></div>
      </>))}
    </div>
  </div>

</div>

export default Dashboard;
