import axios from "axios";
import { notification } from "antd";

// for more information please check this page : https://axios-http.com/docs/interceptors

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error).then((r) => console.log(r));
  }
);

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response.status === 401) {
    localStorage.removeItem('token')
    localStorage.removeItem('settings')
    window.location.href = "/login"
  }

  if (error.response.status === 403) {
    notification.error({
      message: "Yetkilendirme gerekli",
      description: "Bu işlemi gerçekleştirmek için yetki seviyeniz yeterli değil",
    });
  }

  return Promise.reject(error);
});
