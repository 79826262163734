/*
payload will be like below

start: Start Date,
selectedDays: String

*/

export function CronMapper(payload) {
  const startTimeArray = payload.start.toString().split("T")[1].split(":");
  return `${startTimeArray[1]} ${startTimeArray[0]} * * ${payload.selectedDays}`;
}

/*
payload will be like below

start: Start Date,
selectedDay: Number

*/

export function CronMapperForDayByDay(payload) {
  const startTimeArray = payload.start.toString().split("T")[1].split(":");
  const selectedDay = [payload.selectedDay].toString();
  return `${startTimeArray[1]} ${startTimeArray[0]} * * ${selectedDay}`;
}
