import { useContext, useEffect, useState } from "react";
import logo from "../assets/logo.svg";
import logoText from "../assets/logo-text.svg";
import { notification } from "antd";
import { useNavigate } from "react-router-dom";
import { validEmail, validPhoneNumber } from "../utils/RegExUtility";
import { useDispatch } from "react-redux";
import { useMutation } from 'react-query'
import axios from "axios";
import {
  inquireVerifyCode,
} from "../redux/slices/Auth";
import LoginForm from "../components/LoginForm";
import LoginHeader from "../components/LoginHeader";
import { AppContext } from '../AppContext'

const Login = () => {
  const [emailOrPhone, setEmailOrPhone] = useState(null);
  const [hasOTPSent, setHasOTPSent] = useState(false);
  const [tenantId, setTenantId] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setToken, fetchAllGardens } = useContext(AppContext)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      console.log('setting token');
      setToken(token)
      navigate("/control-center");
    }
  }, []);

  const validatePhoneNumberAndEmail = (e) => {
    return validEmail.test(e) || validPhoneNumber.test(e);
  }

  const generateValidInput = (input) => {
    return validPhoneNumber.test(input) ? `${input}` : input;
  };

  const login = useMutation(({ emailOrPhone, tenantId }) => {
    return axios.post(
      `${process.env.REACT_APP_GARDENER_API_END_POINT}/auth/login`,
      {
        emailOrPhone,
        tenantId
      }
    )
  }, {
    onSuccess: (data, vars, ctx) => {
      console.log(data);

      // regex test data.data.error includes "User doesn't exist" case insensitively 
      if (/User doesn't exist/i.test(data.data.error)) {
        notification.error({
          message: "Kod Gönderilemedi",
          description: `Kullanıcı Bulunamadı!`,
          placement: "topRight",
        });
      } else if (data.data.error) {
        notification.error({
          message: "Kod Gönderilemedi",
          description: `${data.data.error}`,
          placement: "topRight",
        })
      } else {
        notification.success({
          message: "Kod Gönderildi",
          description: `${vars.emailOrPhone} ye kod başarıyla gönderildi`,
          placement: "topRight",
        });

        setEmailOrPhone(vars.emailOrPhone);
        setHasOTPSent(true);
        setTenantId(vars.tenantId)
      }

    },
    onError: (e) => {
      notification.error({
        message: "Something went wrong!",
        placement: "topRight",
      });
    }
  })

  const onSend = async ({ phone, tenantId }) => {
    const trimmedInputValue = "90" + phone.replace(/\s+/g, "");
    if (!validatePhoneNumberAndEmail(trimmedInputValue)) {
      notification.error({
        message: `Girmiş olduğunuz ${phone} yanlış formatta`,
        description: `Telefon 05xxxxxxxxx formatında, Email de test@test.com formatında olmalı`,
        placement: "topRight",
      });
    } else {
      const validInput = generateValidInput(trimmedInputValue);
      login.mutate({ emailOrPhone: validInput, tenantId })
    }
  };

  const verifyCodeCall = (e) => {
    dispatch(
      inquireVerifyCode({
        emailOrPhone: emailOrPhone,
        code: e,
        tenantId
      })
    ).then((response) => {
      if (response?.payload?.payload?.success) {
        applyVerifySuccessOperations(
          response?.payload?.payload?.token,
          response?.payload?.payload?.settings,
          emailOrPhone
        );
      } else {
        notification.error({
          message: "Doğrulama başarısız",
          description: "Kod hatalı",
          placement: "topRight",
        });
      }
    });
  };

  const applyVerifySuccessOperations = (token, settings, emailOrPhone) => {
    localStorage.setItem("token", token);

    if (settings) {
      localStorage.setItem("settings", JSON.stringify(settings));
    }

    setToken(token);

    fetchAllGardens({ background: false, force: true })

    localStorage.setItem(
      "user",
      JSON.stringify({
        username: emailOrPhone,
      })
    );
    navigate("/control-center");
  };

  const onSubmit = async (e) => {
    verifyCodeCall(e);
  };

  return (
    <div className="flex flex-col">
      <LoginHeader logo={process.env.REACT_APP_LOGO || logo} logoText={process.env.REACT_APP_LOGO ? null : logoText} />
      <LoginForm
        emailOrPhone={emailOrPhone}
        hasOTPSent={hasOTPSent}
        onSubmit={onSubmit}
        onSend={onSend}
      />
    </div>
  );
};
export default Login;
