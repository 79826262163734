import React from "react";
import { Select } from "antd";
import PropTypes from "prop-types";

const BaseMapSelection = (props) => {
  return (
    <div
      className="absolute bottom-1 left-1 p-4 text-xs text-right"
      style={{ zIndex: 9999999 }}
    >
      <Select
        defaultValue={props.selectedTileUrl}
        onChange={(e) => props.setSelectedTileUrl(e)}
        className="ml-1 p-1 border rounded-r"
        dropdownStyle={{
          zIndex: 99999999,
          borderRadius: "10px",
          width: "200px",
        }}
        style={{ width: "200px", textAlign: "center" }}
        value={props.selectedTileUrl}
      >
        {props.baseMaps.map((map) => (
          <Select.Option key={map.value} value={map.value}>
            {map.label}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

BaseMapSelection.propTypes = {
  baseMaps: PropTypes.array,
  setSelectedTileUrl: PropTypes.func,
  selectedTileUrl: PropTypes.string,
};

export default BaseMapSelection;
