import { useState, useEffect, useRef, createRef, useLayoutEffect } from "react";
import classes from "./EventScheduler.module.css";
import { CloseOutlined } from "@ant-design/icons";
import { Tooltip } from 'antd';

export default function EventScheduler({
  resources,
  events,
  onTimeRangeSelected,
  onEventDeleted,
  cellDuration,
}) {
  const [hours, setHours] = useState([]);
  const [minuteList, setMinuteList] = useState([]);
  const cellRefs = useRef({});
  const resourcesRefs = useRef({});
  const eventsContainerRef = useRef(null);
  const [cellRefsReady, setCellRefsReady] = useState(false)

  const pad = (d) => {
    return d < 10 ? "0" + d.toString() : d.toString();
  };

  const roundMinutes = (m) => {
    if (m % cellDuration === 0) return m;

    return m + (cellDuration - (m % cellDuration));
  };

  const [eventElements, setEventElements] = useState([]);

  const generateMins = (duration) => {
    // generate minutes
    const max = 60;
    let number = 0;
    let minsArr = [];
    while (number !== max) {
      minsArr.push(number);
      number = number + duration;
    }

    return minsArr
  }

  // TODO: try below strategy
  // useEffect(() => {
  //   const minsArr = generateMins(cellDuration)
  //   setMinuteList(minsArr)
  // }, [cellDuration])

  useEffect(() => {
    if (!events) return;

    if (!resources) return;

    // setCellRefsReady(false)
    resources.forEach((r) => {
      r.children.forEach((c) => {
        if (!resourcesRefs.current[r.id + "__" + c.id])
          resourcesRefs.current[r.id + "__" + c.id] = createRef();
      });
    });

    // generate hours
    let hoursArr = [];
    for (let index = 0; index < 24; index++) {
      hoursArr.push(index);
    }

    const minsArr = generateMins(cellDuration)

    // TODO: try below strategy
    // generate refs for each minute
    //const minsAllArr = generateMins(1)

    // reset refs
    cellRefs.current = {}
    // create refs
    hoursArr.map((hour, hi) => {
      resources.forEach((r, ri) => {
        minsArr.forEach((m, mi) => {
          r.children.forEach((c, ci) => {
            const refId = serializeCellId(hour, m, r.id, c.id);
            // if (!cellRefs.current[refId]) {
            cellRefs.current[refId] = {
              id: { h: hour, m, r: r.id, c: c.id },
              ref: createRef(),
            };
            // }
            // check for end of all loops
            if (hi === hoursArr.length - 1 &&
              ri === resources.length - 1 &&
              mi === minsArr.length - 1 &&
              ci === r.children.length - 1
            ) {
              setCellRefsReady(true)
            }
          });
        });
      });
    })

    setMinuteList(minsArr);
    setHours(hoursArr);

  }, [events, resources, cellDuration]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    if (!cellRefsReady) return;
    if (!events) return;

    if (!resources) return;

    renderEventElements()
  }, [events, resources, cellDuration])

  const getSearchableEndTimeParams = (startTime, endTime) => {
    const NEXT_DAY = new Date(startTime.getTime() + 24 * 60 * 60 * 1000).getDate();
    console.log('NEXT_DAY', NEXT_DAY);
    const HOUR = 60;
    const LAST_HOUR = 23;

    let searchableHour, searchableMinute;

    const endHour = endTime.getHours()
    const endMinute = roundMinutes(endTime.getMinutes())
    const endDate = endTime.getDate()

    console.log('endTimes', endHour, endMinute, endDate);

    if (endDate === NEXT_DAY) {
      searchableHour = LAST_HOUR;
      searchableMinute = HOUR - cellDuration;
    } else if (endMinute === 0) {
      searchableHour = endHour - 1;
      searchableMinute = HOUR - cellDuration;
    } else {
      searchableHour = endHour;
      searchableMinute = endMinute - cellDuration;
    }

    return [searchableHour, searchableMinute]
  }

  const renderEventElements = () => {
    let eventElements = []
    events.forEach((e) => {
      const start = new Date(e.start);
      const end = new Date(e.end);
      const resource = e.resource;

      const startHour = start.getHours()
      const startMinute = roundMinutes(start.getMinutes())

      const [startCell] = Object.values(cellRefs.current).filter(
        (v) =>
          v.id.h === startHour &&
          v.id.m === startMinute &&
          v.id.c === resource
      );

      const [endHour, endMinute] = getSearchableEndTimeParams(start, end)

      const [endCell] = Object.values(cellRefs.current).filter(
        (v) =>
          v.id.h === endHour &&
          v.id.m === endMinute &&
          v.id.c === resource
      );

      console.log(endCell);

      if (!startCell) {
        console.log('failed to find startCell refs', { e, startCell, endCell, q: { s: { sh: startHour, sm: startMinute }, e: { eh: endHour, em: endMinute }, r: resource }, cellRefsReady });
        return
      };

      if (!endCell) {
        console.log('failed to find endCell refs', { e, startCell, endCell, q: { s: { sh: startHour, sm: startMinute }, e: { eh: endHour, em: endMinute }, r: resource }, cellRefsReady });
        return
      };

      const cBox = eventsContainerRef.current.getBoundingClientRect();
      const cBoxScrollLeft = eventsContainerRef.current.scrollLeft;
      const sBox = startCell.ref.current.getBoundingClientRect();
      const eBox = endCell.ref.current.getBoundingClientRect();

      eventElements = [
        ...eventElements,
        {
          top: sBox.top - cBox.top,
          left: sBox.left - cBox.left + cBoxScrollLeft,
          right: cBox.right - eBox.right - cBoxScrollLeft,
          event: e,
        }
      ]

    });

    setEventElements(eventElements);
  }

  // TODO: replace the following logic with proper lifecycle
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!events) return;

    if (!resources) return;

    const timer = setTimeout(() => {
      renderEventElements()
    }, 50)

    return () => clearTimeout(timer)
  }, [cellDuration, events])

  const calculateHourWidth = (p) => minuteList.reduce((p, c) => (p += 40), 0);

  const serializeCellId = (h, m, rId, cId) =>
    h + "__" + m + "__" + rId + "__" + cId;

  /**
   * Delete event logic
   */
  const onEventDelete = (e) => {
    onEventDeleted({
      e: {
        data: e.event
      }
    })
  };

  /**
   * Create event logic
   */
  const [newEvent, setNewEvent] = useState(null);
  const [isMouseDown, setIsMouseDown] = useState(false);

  const isWateringResource = (resourceId) => {
    return resourceId.endsWith('_watering');
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // clear drawing
    Object.values(cellRefs.current).forEach((c) => {
      if (!c.ref.current) return;

      const isWatering = isWateringResource(c.id.c);
      c.ref.current.style.background = isWatering ? "#e7eeff" : "#fff";
    });

    if (!newEvent) return;

    let { start, end } = newEvent;

    const cells = Object.values(cellRefs.current).filter(
      (c) =>
        c.id.r === start.r &&
        c.id.c === start.c &&
        c.id.h * 60 + c.id.m >= start.h * 60 + start.m &&
        c.id.h * 60 + c.id.m <= end.h * 60 + end.m
    );

    // draw new event placeholder
    if (cells.length > 0) {
      if (isMouseDown) {
        cells.forEach((c) => {
          c.ref.current.style.background = "#eef";
        });
      } else {
        cells.forEach((c) => {
          const isWatering = isWateringResource(c.id.c);
          c.ref.current.style.background = isWatering ? "#e7eeff" : "#fff";
        });

        const buildEndTime = (event, cellDuration) => {
          if (event.end.m + cellDuration === 60) {
            if (event.end.h + 1 === 24) {
              return `1970-01-02T00:00:00`;
            } else {
              return `1970-01-01T${pad(event.end.h + 1)}:00:00`;
            }
          } else {
            return `1970-01-01T${pad(event.end.h)}:${pad(event.end.m + cellDuration)}:00`;
          }
        }

        const eventObj = {
          id: `${newEvent.start.c}__${pad(newEvent.start.h)}_${pad(newEvent.start.m)}_00__${pad(newEvent.end.h)}_${pad(newEvent.end.m + cellDuration)}_00__${Math.floor((Math.random() + 1) * 1000000)}`,
          start: `1970-01-01T${pad(newEvent.start.h)}:${pad(newEvent.start.m)}:00`,
          end: buildEndTime(newEvent, cellDuration),
          resource: newEvent.start.c
        }

        onTimeRangeSelected(eventObj)
        setNewEvent(null)
      }
    }
  }, [newEvent, isMouseDown]);

  const onCellMouseDown = (e, id) => {
    if (id.readOnly) return;

    const range = { start: id, end: id };
    setNewEvent(range);
    setIsMouseDown(true);
  };

  const [touchStarted, setTouchStarted] = useState(false)

  const onCellTouchStart = (e, id) => {
    if (id.readOnly) return;

    if (!touchStarted) {
      const range = { start: id, end: id };
      setNewEvent(range);
      setIsMouseDown(true);
      setTouchStarted(true)
    } else {
      if (isMouseDown && newEvent && newEvent.start)
        setNewEvent((prev) => ({ ...prev, end: id }));

      setIsMouseDown(false);
      setTouchStarted(false)
    }
  };

  const onCellTouchMove = () => {
    setNewEvent(null);
    setIsMouseDown(false);
    setTouchStarted(false)
  }

  const onCellTouchEnd = (e) => {
    e.preventDefault()
  }

  const onCellMouseOver = (id) => {
    if (isMouseDown && newEvent && newEvent.start)
      setNewEvent((prev) => ({ ...prev, end: id }));
  };

  const onCellMouseUp = () => {
    setIsMouseDown(false);
  };

  const getEventElementClass = (e) => {
    if (e.event.readOnly) return classes.DisabledEventElement

    switch (e.event.status) {
      case 'new':
        return classes.NewEventElement;
      case 'deleted':
        return classes.DeletedEventElement;
      default:
        return classes.EventElement;

    }
  }

  const [headerTop, setHeaderTop] = useState(0)
  const headerRef = useRef()
  const handleWindowScroll = () => {
    if (!eventsContainerRef.current) return;

    const headerTopPos = eventsContainerRef.current.getBoundingClientRect().top
    if (eventsContainerRef.current.getBoundingClientRect().top < 0) {
      if (headerRef.current) {
        setHeaderTop(headerTopPos * -1)
      }
    } else {
      if (headerRef.current) {
        setHeaderTop(0)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleWindowScroll);

    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
    }
  }, [])

  const wheelTimeout = useRef()
  const onContainerScroll = e => {
    if (e.deltaY === 0) return;

    eventsContainerRef.current.scrollTo({
      left: eventsContainerRef.current.scrollLeft + e.deltaY * 3,
      behavior: "smooth"
    });


    clearTimeout(wheelTimeout.current)

    wheelTimeout.current = setTimeout(() => {
      wheelTimeout.current = false
    }, 300)
  }

  // block the body from scrolling (or any other element)
  useEffect(() => {
    const cancelWheel = e => wheelTimeout.current && e.preventDefault()
    document.body.addEventListener('wheel', cancelWheel, { passive: false })
    return () => document.body.removeEventListener('wheel', cancelWheel)
  }, [])

  return (
    <div className={classes.SchedulerWrapper}>
      <div className={classes.RightPanel}>
        {/* <div className={classes.GroundName}>name goes here</div> */}
        <div className={classes.HourBockWrapper}>
          <div className={classes.LeftPanel}>
            <div style={{ height: "60px", minWidth: "150px" }}></div>
            {resources.map((e) => (
              <>
                <div
                  // onClick={() => toggleResourceChildren(e)}
                  className={classes.Device}
                >
                  <Tooltip title={e.name} placement="topLeft">
                    {e.name}
                  </Tooltip>
                </div>
                <div className={classes.ChildDeviceList}>
                  {e.children.map((c) => (
                    <div
                      key={c.id}
                      className={classes.ChildDevice}
                      ref={resourcesRefs.current[e.id + "__" + c.id]}
                    >
                      {c.name}
                    </div>
                  ))}
                </div>
              </>
            ))}
          </div>
          <div
            className="overflow-x-scroll relative"
            ref={eventsContainerRef}
            onMouseUp={onCellMouseUp}
            onWheel={onContainerScroll}
          // style={{ touchAction: 'none' }}
          >
            <div ref={headerRef} className="flex absolute top-0 left-0" style={{ top: headerTop }}>{
              cellRefsReady && hours.map((hour) => <div key={hour}>
                <div
                  className={classes.Hour}
                  style={{
                    width: calculateHourWidth(),
                  }}
                >
                  {pad(hour)}
                </div>
                <div className={classes.MinutesList}>
                  {minuteList.map((number) => {
                    return (
                      <div key={number + "2nd"} className={classes.Minute}>
                        {pad(number)}
                      </div>
                    );
                  })}
                </div>
              </div>)
            }</div>
            <div className={classes.SquarePanel}>{
              cellRefsReady && hours.map((hour) => <div key={hour}>
                <div
                  className={classes.Hour}
                  style={{
                    width: calculateHourWidth(),
                  }}
                >
                  {pad(hour)}
                </div>
                <div className={classes.MinutesList}>
                  {minuteList.map((number) => {
                    return (
                      <div key={number + "2nd"} className={classes.Minute}>
                        {pad(number)}
                      </div>
                    );
                  })}
                </div>
                {resources.map((resource, resourceIndex) => {
                  return (
                    <div key={resource.id} className={classes.SquareBlock}>
                      {minuteList.map((minute, index) => {
                        return (
                          <div key={index} className={classes.SquareVerticalLine}>
                            <div className={classes.SquareDisabled}></div>
                            {resource.children.map((child) => {
                              const isWatering = isWateringResource(child.id);
                              const isReadOnly = resource.readOnly || child.readOnly;
                              return (
                                <div
                                  key={child.id}
                                  className={`${classes.Square} ${isWatering ? classes.WateringSquare : ''} ${isReadOnly ? classes.ReadOnlySquare : ''}`}
                                  ref={
                                    cellRefs.current[
                                      serializeCellId(hour, minute, resource.id, child.id)
                                    ].ref
                                  }
                                  onMouseDown={(e) => {
                                    if (!isReadOnly) {
                                      onCellMouseDown(e, {
                                        h: hour,
                                        m: minute,
                                        r: resource.id,
                                        c: child.id,
                                        readOnly: isReadOnly,
                                      });
                                    }
                                  }}
                                  onTouchStart={(e) => {
                                    if (!isReadOnly) {
                                      onCellTouchStart(e, {
                                        h: hour,
                                        m: minute,
                                        r: resource.id,
                                        c: child.id,
                                      });
                                    }
                                  }}
                                  onTouchMove={isReadOnly ? null : onCellTouchMove}
                                  onTouchEnd={isReadOnly ? null : onCellTouchEnd}
                                  onMouseOver={() => {
                                    if (!isReadOnly) {
                                      onCellMouseOver({
                                        h: hour,
                                        m: minute,
                                        r: resource.id,
                                        c: child.id,
                                      });
                                    }
                                  }}
                                ></div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>)
            }</div>
            {eventElements.map((e) => (
              <Tooltip
                key={e.event.id}
                title={e.event.text || `${new Date(e.event.start).toLocaleTimeString()} - ${new Date(e.event.end).toLocaleTimeString()}`}
                placement="top"
              >
                <div
                  className={getEventElementClass(e)}
                  style={{
                    left: e.left,
                    top: e.top,
                    right: e.right + 2,
                  }}
                >
                  <div className={classes.EventContent}>
                    {e.event.text || `${new Date(e.event.start).toLocaleTimeString()} - ${new Date(e.event.end).toLocaleTimeString()}`}
                  </div>
                  {(!e.event.readOnly && (e.event.status === 'new' || !e.event.status)) && (
                    <div
                      onClick={(event) => {
                        event.stopPropagation();
                        onEventDelete(e);
                      }}
                      className={classes.EventDelete}
                    >
                      <CloseOutlined />
                    </div>
                  )}
                </div>
              </Tooltip>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
