import { useContext, useEffect, useState } from "react";
import { useNavigate, Outlet, NavLink } from "react-router-dom";
import "./ControlCenter.css";
import AppContainer from "../layout/AppContainer";
import { AppContext } from '../AppContext'

const ControlCenter = () => {
  const navigate = useNavigate();
  const { setToken, role } = useContext(AppContext)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      setToken(token)
    }
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // if role is telecom_user, then redirect to signal-map
    if (role === "telecom_user") {
      navigate("/signal-map");
    }
  }, [role]);

  return (
    <>
      <AppContainer>
        {role && !["telecom_user"].includes(role) && <div className="px-2">
          <div className="py-4 pb-0 flex flex-row gap-3 text-white ">
            {role && !["garden_tester"].includes(role) && <NavLink to="gardens" className="btn">
              Parklar/Bahçeler
            </NavLink>}
            {role && !["garden_tester"].includes(role) && <NavLink to="dashboard" className="btn">
              Raporlar
            </NavLink>}
            <NavLink to="users" className="btn">
              Ayarlar
            </NavLink>
          </div>
          <div className="">
            <Outlet />
          </div>
        </div>}
      </AppContainer>
    </>
  );
};

export default ControlCenter;
