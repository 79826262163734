const calculateBatteryLevel = (v, offset, model) => {
    if (!v) return '-';

    const modelLimits = {
        BL: {
            high: 4500,
            low: 3500
        }
    }

    const high = modelLimits[model]?.high || 4250
    const low = modelLimits[model]?.low || 3500
    const value = parseInt(v) + (parseInt(offset) || 0)
    if (value <= low) {
        return '0%'
    } else if (value >= high) {
        return '100%'
    } else {
        return ((value - low) * 100 / (high - low)).toFixed(0) + '%'
    }
}

const getSignalStrengthForBars = (rawValue) => {
    const value = -1 * parseInt(rawValue || "0")
    if (value >= 120) return 0;
    if (value >= 100 && value <= 119) return 1;
    if (value >= 95 && value <= 99) return 2;
    if (value >= 75 && value <= 94) return 3;
    if (value >= 70 && value <= 74) return 4;
    if (value <= 69) return 5;
}

export {
    calculateBatteryLevel,
    getSignalStrengthForBars
}