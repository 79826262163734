import React, { useEffect, useState } from "react";
import { Input } from "antd";
import { AiOutlineLoading } from "react-icons/ai";
import axios from "axios";
import PropTypes from "prop-types";
import { useQuery } from 'react-query'

const LoginForm = (props) => {
  const [selectedTenant, setSelectedTenant] = useState(null)

  const tenant = useQuery(['GetTenantByHost'], () => {
    return axios.get(process.env.REACT_APP_PLANNER_API_END_POINT + '/tenant/by-host?host=' + window.location.hostname)
  }, { enabled: !selectedTenant })

  useEffect(() => {
    if (!tenant.data) return;

    setSelectedTenant(tenant.data.data.tenant)
  }, [tenant.data])

  return (
    <div className="relative flex justify-center">
      <div className="rounded-md absolute -top-10 p-4 bg-white shadow-md">
        <div className="flex items-center justify-center gap-4 flex-wrap">
          {tenant.isLoading && <div className=" animate-spin"><AiOutlineLoading /></div>}
          {tenant.isError && <div className=" text-gray-700 text-xs">Something went wrong. Please contact the administrator.</div>}
          {tenant.isSuccess && selectedTenant && <div
            className={`flex px-3 py-2 rounded-md box-border text-lg font-bold gap-3 items-center w-full justify-center max-w-screen-sm`} >
            <img src={selectedTenant.settings.logoUrl} alt="" className=" w-3/4 rounded-md overflow-hidden" />
          </div>}
        </div>
        {selectedTenant && <div className="flex flex-col mt-4">
          <Input.Search
            addonBefore="+90"
            placeholder="5xx xxx xx xx"
            onSearch={(e) => props.onSend({ phone: e, tenantId: selectedTenant.id })}
            enterButton={props.hasOTPSent ? "Kodu Tekrar Gönder" : "Kodu Gönder"}
            size="large"
          />
          {props.hasOTPSent && (
            <>
              <p className="font-light text-gray-400 text-xs mt-4">
                Lütfen <b>{props.emailOrPhone}</b> adresine gönderilen kodu
                giriniz
              </p>
              <Input.Search
                placeholder="Kod"
                onSearch={props.onSubmit}
                enterButton="Gönder"
                size="large"
              />
            </>
          )}
        </div>}
      </div>
    </div>
  );
};

LoginForm.propTypes = {
  onSend: PropTypes.func,
  hasOTPSent: PropTypes.bool,
  emailOrPhone: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default LoginForm;
