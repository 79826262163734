export const BASE_MAP_URL =
  "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png";
export const MAP_ATTRIBUTION_URL =
  '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors';

export const BASE_MAPS = [
  { label: "CartoDB", value: BASE_MAP_URL },
  {
    label: "Stadia",
    value:
      "https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png",
  },
  {
    label: "Arcgis Uydu",
    value:
      "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
  },
  {
    label: "Google Yol",
    value: "https://mts0.google.com/vt/lyrs=m&x={x}&y={y}&z={z}",
  },
  {
    label: "Google Uydu",
    value: "https://mts0.google.com/vt/lyrs=y&x={x}&y={y}&z={z}",
  },
  {
    label: "Google Fiziksel",
    value: "https://mts0.google.com/vt/lyrs=p&x={x}&y={y}&z={z}",
  },
  {
    label: "Google Yol 2",
    value: "https://mts0.google.com/vt/lyrs=h&x={x}&y={y}&z={z}",
  },
];

export const GeoJSON_STYLE = {
  fillColor: "#8ABE61",
  fillOpacity: 0.5,
  color: "#8ABE61",
  stroke: 1,
};
