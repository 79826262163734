/*
payload should be like below

start: Date,
end: Date,
totalSubDeviceLength: Number,
valve: Number,
*/

export function DurationMapper(payload) {
  const dateDifference = Math.abs(
    new Date(payload.end) - new Date(payload.start)
  );
  const minuteDifference = Math.floor(dateDifference / 1000 / 60);
  let array = new Array(payload.totalSubDeviceLength - 1).fill(0);
  array[(payload?.valve || 0) - 1] = minuteDifference;
  return array;
}
