import axios from "axios";

// for more information please check this page : https://axios-http.com/docs/interceptors

axios.interceptors.request.use(
    (config) => {
        if (!config.headers["x-api-version"]) {
            config.headers["x-api-version"] = 'v2';
        }

        return config;
    },
    (error) => {
        Promise.reject(error).then((r) => console.log(r));
    }
);
