import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import "./GardenList.css";
import { MapContainer, Marker, Popup } from "react-leaflet";
import { AppContext } from "../AppContext";
import MapGardenList from "../components/MapGardenList";
import { BASE_MAPS } from "../utils/MapUtils";
import GardenListSearch from "../components/GardenListSearch";
import BaseMapSelection from "../components/BaseMapSelection";
import GardenListMapFunctionalBox from "../components/GardenListMapFunctionalBox";
import { transformTurkish } from "../utils/RegExUtility";
import useWindowSize from "../utils/WindowSize";
import { deviceModelPulsingPin } from '../components/MarkerIcon'
import { useQuery } from "react-query"
import { Tag } from "antd";


const GardensList = () => {
  const navigate = useNavigate();
  const [mapCenter, setMapCenter] = useState(null);
  const [visibleParks, setVisibleParks] = useState([]);
  const [filteredParks, setFilteredParks] = useState(null);
  const [mouseOverFeature, setMouseOverFeature] = useState(null);
  const { setSelectedGarden, gardens, loadingGardens, fetchAllGardens, tenantSettings } = useContext(AppContext);
  const [selectedTileUrl, setSelectedTileUrl] = useState(BASE_MAPS[0].value);
  const [streetMap, setStreetMap] = useState(false);
  const [searchText, setSearchText] = useState(null)
  const [tags, setTags] = useState([])
  const [selectedTags, setSelectedTags] = useState([])
  const { width } = useWindowSize()

  const allDevices = useQuery(['AllDevices'], () => {
    return axios.get(process.env.REACT_APP_PLANNER_API_END_POINT + '/device/list')
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const fetchGardenTags = async () => {
      try {
        const res = await axios.get(process.env.REACT_APP_PLANNER_API_END_POINT + '/tag?resource=gardens')
        setTags(res.data.tags)
      } catch (error) {
        console.log(error);
      } finally {
      }
    }

    fetchAllGardens()
    fetchGardenTags()
  }, [])

  useEffect(() => {
    if (tenantSettings?.defaultBaseMap?.value) {
      setSelectedTileUrl(tenantSettings?.defaultBaseMap?.value)
    }
  }, [tenantSettings?.defaultBaseMap?.value])

  const getFilteredGardens = () => {
    let filteredGardens = gardens
    if (searchText && searchText.trim().length > 1) {
      filteredGardens = filteredGardens.filter((p) => {
        return RegExp(`.*${transformTurkish(searchText.trim())}.*`, "gi").test(transformTurkish(p.geoJson.properties.MAHAL_ADI)) ||
          RegExp(`.*${searchText.trim()}.*`, "gi").test(p.id)
      })
    }


    if (selectedTags.length > 0) {
      console.log(filteredGardens);
      console.log(selectedTags);
      filteredGardens = filteredGardens.filter((p) => p.tags && selectedTags.every(t => p.tags.includes(t)))
    }

    return filteredGardens;
  }

  const makeParkVisible = (p) => {
    setVisibleParks([p]);
    setMapCenter(p);
  };

  const onSearch = (e) => {
    const val = e.target.value;
    setSearchText(val)
  };

  const onEdit = (p) => {
    setSelectedGarden({
      id: p.id,
      name: p.name,
    });
    navigate(`/control-center/gardens/${p.id}`);
  };

  const onPolygonClick = (e) => {
    navigate(`/control-center/gardens/${e.target.feature.properties.MAHAL_ID}`);
  };

  const onPolygonMouseover = (e) => {
    setMouseOverFeature(e.target.feature);
  };

  const onTagsChange = (e) => {
    setSelectedTags(e)
  }

  const getIcon = (type, model) => {
    return deviceModelPulsingPin({ alive: type, model })
  }

  const [selectedDeviceTypes, setSelectedDeviceTypes] = useState(['BL', 'EL', 'LT', 'ELS'])

  const onSelectedDeviceTypeChanged = (checked, tag) => {
    console.log(checked, selectedDeviceTypes, tag);
    setSelectedDeviceTypes(p => checked ? [...p, tag] : p.filter(t => t !== tag))
  }

  return (
    <>
      <div className="container flex flex-col gap-4 p-4 bg-opacity-20 bg-white rounded-md rounded-tl-none">
        <div className="">
          <div className="flex flex-row gap-4">
            <GardenListSearch
              parksData={getFilteredGardens()}
              filteredParks={filteredParks}
              makeParkVisible={makeParkVisible}
              onEdit={onEdit}
              onSearch={onSearch}
              loading={loadingGardens}
              tags={tags}
              onTagsChange={onTagsChange}
            />

            {width > 768 && <div className=" relative container bg-white rounded-md overflow-hidden hidden md:block">
              <div className=" absolute top-3 left-12 bg-white rounded-md px-1 py-1 text-xs flex gap-1 flex-col shadow-sm" style={{ zIndex: 999 }}>
                <Tag.CheckableTag style={{ backgroundColor: selectedDeviceTypes.includes('BL') && '#3b82f680' }} key={'BL'} checked={selectedDeviceTypes.includes('BL')} onChange={(c) => onSelectedDeviceTypeChanged(c, 'BL')}>
                  <div className="flex items-center gap-1">
                    <div className=" fill-current text-yellow-400 h-3 w-3">
                      <svg viewBox="0 0 140 123" xmlns="http://www.w3.org/2000/svg">
                        <path d="M63.0718 3.99999C66.151 -1.33334 73.849 -1.33333 76.9282 4L138.416 110.5C141.495 115.833 137.646 122.5 131.488 122.5H8.51218C2.35378 122.5 -1.49521 115.833 1.584 110.5L63.0718 3.99999Z" />
                      </svg>
                    </div>
                    <div className=" text-xs">BL</div>
                  </div>
                </Tag.CheckableTag>
                <Tag.CheckableTag style={{ backgroundColor: selectedDeviceTypes.includes('EL') && '#3b82f680' }} key={'EL'} checked={selectedDeviceTypes.includes('EL')} onChange={(c) => onSelectedDeviceTypeChanged(c, 'EL')}>
                  <div className="flex items-center gap-1">
                    <div className=" fill-current text-blue-500 h-3 w-3">
                      <svg viewBox="0 0 125 125" xmlns="http://www.w3.org/2000/svg">
                        <rect width="125" height="125" rx="8" />
                      </svg>
                    </div>
                    <div className=" text-xs">EL</div>
                  </div>
                </Tag.CheckableTag>
                <Tag.CheckableTag style={{ backgroundColor: selectedDeviceTypes.includes('ELS') && '#3b82f680' }} key={'ELS'} checked={selectedDeviceTypes.includes('ELS')} onChange={(c) => onSelectedDeviceTypeChanged(c, 'ELS')}>
                  <div className="flex items-center gap-1">
                    <div className=" fill-current text-yellow-400 h-3 w-3">
                      <svg viewBox="0 0 125 125" xmlns="http://www.w3.org/2000/svg">
                        <rect width="125" height="125" rx="8" />
                      </svg>
                    </div>
                    <div className=" text-xs">ELS</div>
                  </div>
                </Tag.CheckableTag>
                <Tag.CheckableTag style={{ backgroundColor: selectedDeviceTypes.includes('LT') && '#3b82f680' }} key={'LT'} checked={selectedDeviceTypes.includes('LT')} onChange={(c) => onSelectedDeviceTypeChanged(c, 'LT')}>
                  <div className="flex items-center gap-1">
                    <div className=" fill-current text-blue-500 h-3 w-3">
                      <svg viewBox="0 0 126 126" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="63" cy="63" r="63" />
                      </svg>
                    </div>
                    <div className=" text-xs">LT</div>
                  </div>
                </Tag.CheckableTag>

              </div>
              <GardenListMapFunctionalBox
                mouseOverFeature={mouseOverFeature}
                setStreetMap={setStreetMap}
                setSelectedTileUrl={setSelectedTileUrl}
              />

              <BaseMapSelection
                setSelectedTileUrl={setSelectedTileUrl}
                baseMaps={BASE_MAPS}
                selectedTileUrl={selectedTileUrl}
              />

              {!!selectedTileUrl ? (
                <MapContainer
                  key="map"
                  center={[0, 0]}
                  zoom={10}
                  scrollWheelZoom={true}
                >
                  {allDevices.data && allDevices.data.data.devices.map(d => {
                    if (d.location && d.location.lat && d.location.lng && selectedDeviceTypes.includes(d.model)) {
                      return <Marker position={[d.location.lat, d.location.lng]} icon={getIcon(d.alive, d.model)}>
                        <Popup>{d.name}</Popup>
                      </Marker>
                    }
                  })}
                  <MapGardenList
                    mapCenter={mapCenter}
                    features={gardens.map(g => g.geoJson)}
                    onPolygonClick={onPolygonClick}
                    onPolygonMouseover={onPolygonMouseover}
                    streetMap={streetMap}
                    selectedTileLayer={selectedTileUrl}
                  />
                </MapContainer>
              ) : (
                <></>
              )}
            </div>}
          </div>
        </div>
      </div>
    </>
  );
};

export default GardensList;
