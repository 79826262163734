import { GeoJSON, Popup, TileLayer, useMap } from "react-leaflet";
import { GeoJSON_STYLE, MAP_ATTRIBUTION_URL } from "../utils/MapUtils";
import { useEffect, useRef } from "react";
import PropTypes from "prop-types";

const MapGardenList = (props) => {
  const map = useMap();
  const gjRef = useRef();
  const tileRef = useRef();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (props.mapCenter === null)
      map.flyTo([process.env.REACT_APP_MAP_CENTER_LAT, process.env.REACT_APP_MAP_CENTER_LNG], parseInt(process.env.REACT_APP_MAP_ZOOM), { animate: true, duration: 1.2 }); // zoom to istanbul by default //duration default 0.25, 1 between 2 is the best for the fast animation
    if (gjRef.current) {
      const bounds = gjRef.current.getBounds();
      map.flyToBounds(bounds, {
        maxZoom: 17,
        animate: true,
        duration: 1.2,
      });
    }
  }, [props.mapCenter, props.selectedTileLayer]);

  useEffect(() => {
    if (tileRef.current) {
      tileRef.current.setUrl(props.selectedTileLayer);
    }
  }, [props.selectedTileLayer]);

  const onEachFeature = (feature, layer) => {
    layer.on({
      click: props.onPolygonClick,
      mouseover: props.onPolygonMouseover,
    });
  };

  const getStreetMapOpacity = () => {
    if (props.streetMap) {
      return 1;
    } else if (props.mixed) {
      return 0.4;
    } else {
      return 0;
    }
  };

  // const getSatelliteOpacity = () => {
  //     if (props.satellite) {
  //         return 1
  //     } else if (props.mixed) {
  //         return 0.4
  //     } else {
  //         return 0
  //     }
  // }

  return (
    <>
      {props.mapCenter && (
        <GeoJSON
          on
          ref={gjRef}
          onEachFeature={onEachFeature}
          key={props.mapCenter.geoJson.properties.MAHAL_ID + "center"}
          data={props.mapCenter.geoJson}
        >
          <Popup>
            {props.mapCenter.geoJson.properties.MAHAL_ADI}
            <br />
            {props.mapCenter.geoJson.properties.MALIKTIP}
          </Popup>
        </GeoJSON>
      )}
      {props.features &&
        props.features.map((p) => (
          <GeoJSON
            style={(f) => (GeoJSON_STYLE)}
            onEachFeature={onEachFeature}
            key={p.properties.MAHAL_ID}
            data={p}
          >
            <Popup>
              {p.properties.MAHAL_ADI}
              <br />
              {p.properties.MALIKTIP}
            </Popup>
          </GeoJSON>
        ))}

      <TileLayer
        ref={tileRef}
        attribution={MAP_ATTRIBUTION_URL}
        url={props.selectedTileLayer}
      />

      {(props.streetMap || props.mixed) && (
        <TileLayer
          opacity={getStreetMapOpacity()}
          attribution={MAP_ATTRIBUTION_URL}
          url={props.selectedTileLayer}
        />
      )}
    </>
  );
};

MapGardenList.propTypes = {
  mapCenter: PropTypes.object,
  features: PropTypes.array,
  streetMap: PropTypes.bool,
  mixed: PropTypes.object,
  onPolygonClick: PropTypes.func,
  onPolygonMouseover: PropTypes.func,
  selectedTileLayer: PropTypes.string,
};

export default MapGardenList;
