import { createSlice } from "@reduxjs/toolkit";
import { DeviceService } from "../../shared/services/DeviceService";

export const initialState = {
  devices: [],
  devicesLoading: false,
  devicesHasErrors: false,
};

const devicesSlice = createSlice({
  name: "devices",
  initialState,
  reducers: {
    getDevices: (state) => {
      state.devicesLoading = true;
    },
    getDevicesSuccess: (state, payload) => {
      state.devices = payload;
      state.devicesLoading = false;
      state.devicesHasErrors = false;
    },
    getDevicesFailure: (state) => {
      state.loading = false;
      state.devicesHasErrors = true;
    },
  },
});

export const { getDevices, getDevicesFailure, getDevicesSuccess } =
  devicesSlice.actions;

export const devicesSelector = (state) => state.devices;

export default devicesSlice.reducer;

export function fetchDevices() {
  return async (dispatch) => {
    dispatch(getDevices());
    try {
      const response = await DeviceService.getDeviceList();
      const data = (await response.data) || [];
      dispatch(getDevicesSuccess(data));
    } catch (error) {
      dispatch(getDevicesFailure());
    }
  };
}
