import { useState } from "react";
import { useMutation } from 'react-query'
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { notification, Select, InputNumber, Switch } from "antd";
import tr from '../i18n'
import ActiveDaySelection from "./ActiveDaySelection";
import DeleteButton from "./DeleteButton";
import axios from "axios";

const ManagePlans = ({ device, onUpdate }) => {
    const [startHour, setStartHour] = useState(null)
    const [startMinute, setStartMinute] = useState(null)
    const [durations, setDurations] = useState([0, 0, 0, 0, 0, 0, 0, 0])
    const [selectedDays, setSelectedDays] = useState([])

    const getValves = (selectedDevice) => {
        if (!(selectedDevice?.Device?.valves)) return [];

        const valves = JSON.parse(selectedDevice.Device.valves)
        return Object.values(valves)
    }

    const pad = (d) => {
        return d < 10 ? "0" + d.toString() : d.toString();
    };

    const generateMinutesArray = () => {
        const arr = []
        for (let i = 0; i < 60; i++) {
            arr.push([pad(i)])
        }
        return arr
    }

    const generateHoursArray = () => {
        const arr = []
        for (let i = 0; i < 24; i++) {
            arr.push([pad(i)])
        }
        return arr
    }

    const getTimeFromCron = (plan) => {

        // this is cron 15 23 * * 1,2,3,4,5,6,0
        // get 23:15 as the time

        const cron = plan?.cron
        if (!cron) return null

        const parts = cron.split(" ")
        const hour = parts[1]
        const minute = parts[0]
        return `${hour}:${minute}`
    }

    const getActiveDaysFromCron = (plan) => {
        const cron = plan?.cron
        if (!cron) return null
        const parts = cron.split(" ")
        const days = parts[4]

        if (days === "*") return tr("Everyday")

        const daysOfWeek = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']

        const daysArr = days.split(",").map(d => tr(daysOfWeek[d]))

        return daysArr
    }


    const createPlanMutation = useMutation((data) => {
        return axios.post(process.env.REACT_APP_PLANNER_API_END_POINT + '/plan', data)
    }, {
        onSuccess: () => {
            onUpdate();

            notification.success({
                message: tr("Plan created"),
                description: tr("Plan created successfully"),
            });
        },
        onError: (error) => {
            notification.error({
                message: tr("Plan creation failed"),
                description: tr("Plan creation failed"),
            });
        }
    })

    const deletePlanMutation = useMutation((data) => {
        return axios.delete(process.env.REACT_APP_PLANNER_API_END_POINT + '/plan?' + data)
    }, {
        onSuccess: () => {
            onUpdate();

            notification.success({
                message: tr("Plan deleted"),
                description: tr("Plan deleted successfully"),
            });
        },
        onError: (error) => {
            notification.error({
                message: tr("Plan deletion failed"),
                description: tr("Plan deletion failed"),
            });
        }
    })

    const [plansCollapsed, setPlansCollapsed] = useState(true)

    const getActivePlans = (plans) => {
        return plans.filter(p => p.status === "active")
    }

    const getMergedPlans = (plans) => {
        // get qualified plans for merging
        // plan.durations must have only one none zero value

        const qualifiedPlans = plans.filter((p) => {
            const durations = p.durations
            const noneZeroDurations = durations.filter(d => d !== 0)
            return noneZeroDurations.length === 1
        })

        // get non qualified plans for merging
        // plan.durations must have more than one none zero value
        const nonQualifiedPlans = plans.filter((p) => {
            const durations = p.durations
            const noneZeroDurations = durations.filter(d => d !== 0)
            return noneZeroDurations.length > 1
        })

        const processedPlans = qualifiedPlans.map((p) => {
            const cron = p.cron
            const parts = cron.split(" ")
            const hour = parts[1]
            const minute = parts[0]
            const days = parts[4]

            const minutes = parseInt(hour) * 60 + parseInt(minute)
            const duration = p.durations.reduce((acc, d) => acc + d, 0)
            const startTime = minutes
            const endTime = minutes + duration

            const newPlan = {
                ...p,
                startTime: startTime,
                endTime: endTime,
                minutes: minutes,
                duration: duration
            }

            return newPlan

        })

        // sort plans by start time
        processedPlans.sort((a, b) => a.startTime - b.startTime)

        // group processed plans where the end time of one plan is the start time of the next plan
        const groupedPlans = []
        let currentGroup = []
        // clone processed plans
        let processedPlansClone = JSON.parse(JSON.stringify(processedPlans))
        for (const p of processedPlans) {
            if (currentGroup.length === 0) {
                currentGroup.push(p)
                // remove the first plan from the clone
                processedPlansClone = processedPlansClone.filter((pp) => pp._id !== p._id)
            } else {
                const lastPlan = currentGroup[currentGroup.length - 1]
                // find the next plan in the clone
                const nextPlan = processedPlansClone.find((pp) => pp.startTime === lastPlan.endTime)
                if (nextPlan) {
                    currentGroup.push(nextPlan)
                    // remove the next plan from the clone
                    processedPlansClone = processedPlansClone.filter((pp) => pp._id !== nextPlan._id)
                } else {
                    // if there is no next plan, push the current group to the grouped plans
                    groupedPlans.push(currentGroup)
                    // reset the current group
                    currentGroup = [p]
                }
            }
        }

        if (currentGroup.length > 0) {
            groupedPlans.push(currentGroup)
        }

        // construct merged plans
        const mergedPlans = groupedPlans.map((g) => {
            const firstPlan = g[0]

            const cron = firstPlan.cron
            const parts = cron.split(" ")
            const hour = parts[1]
            const minute = parts[0]
            const days = parts[4]

            const mergedPlan = {
                ...firstPlan,
                cron: `${minute} ${hour} * * ${days}`,
            }

            // 8 element array with all zeros
            const durations = [0, 0, 0, 0, 0, 0, 0, 0]

            mergedPlan.durations = durations.map((d, i) => {
                const plan = g.find(p => p.durations[i] !== 0)
                if (plan) {
                    return plan.durations[i]
                } else {
                    return 0
                }
            })

            return mergedPlan
        })

        return [...mergedPlans, ...nonQualifiedPlans]
    }

    const [showMergedPlans, setShowMergedPlans] = useState(true)

    return <div className="flex flex-col text-xs text-gray-700   border rounded-md ">

        <div className="p-4 border-b flex flex-col gap-3 w-full">
            <div className="flex flex-col gap-1">
                <div className="text-xs text-gray-700">Start time</div>
                <div className="flex gap-1">
                    <Select placeholder={tr("hour")} onChange={
                        (v) => {
                            setStartHour(v)
                        }
                    }>
                        {generateHoursArray().map(h => <Select.Option key={h}>{h}</Select.Option>)}
                    </Select>
                    <Select
                        placeholder={tr("minute")}
                        onChange={
                            (v) => {
                                setStartMinute(v)
                            }
                        }
                    >
                        {generateMinutesArray().map(m => <Select.Option key={m}>{m}</Select.Option>)}
                    </Select>
                </div>
            </div>
            <div className="flex flex-col gap-1">
                <div className="text-xs text-gray-700">Active days</div>
                <ActiveDaySelection onChange={(e) => { setSelectedDays(e) }} selectedDays={selectedDays} />
            </div>
            <div className="flex flex-col gap-1">
                <div className="text-xs text-gray-700">Valve duration</div>
                <div className="flex gap-1 flex-wrap">
                    {getValves(device).map((v, i) =>
                        <div className="flex flex-col text-xs">
                            {/* {v.name} */}
                            <InputNumber disabled={v.state === "off"} min={0} style={{ width: 45 }} defaultValue={0} onChange={(v) => setDurations(p => { p[i] = v; return p })} />
                        </div>
                    )}
                </div>
            </div>
            <div>
                <button className=" text-xs bg-gray-100 p-2 px-4 rounded-sm text-blue-500" onClick={() => {
                    createPlanMutation.mutate({
                        deviceSerial: device.deviceSerial,
                        name: "plan-web-app-" + new Date().getTime(),
                        cron: `${startMinute} ${startHour} * * ${selectedDays.join(",")}`,
                        durations: durations
                    })
                }}>{tr("Create plan")}</button>
            </div>
        </div>
        <div className={`p-4 pt-8 gap-4 text-sm flex items-start flex-wrap relative overflow-hidden ${plansCollapsed && 'max-h-0'}`} >
            <div className=" bg-gray-50 text-xs border p-1 rounded-md absolute -top-2 left-1/2" onClick={() => setPlansCollapsed(p => !p)}>{plansCollapsed ? <DownOutlined /> : <UpOutlined />}</div>
            {getActivePlans(device.plans).length === 0 && <div className="text-gray-500 text-xs absolute left-0 top-0 p-1 bg-gray-50">{tr("No plans")}</div>}
            <div className="text-gray-500 text-xs absolute right-0 top-0 p-1 bg-gray-50">Merged <Switch size="small" checked={showMergedPlans} onChange={(e) => setShowMergedPlans(e)} /></div>
            {getActivePlans(device.plans).length > 0 && <div className="text-gray-500 text-xs  absolute left-0 top-0 p-1 bg-gray-50">{getActivePlans(device.plans).length} {tr(`plan${getActivePlans(device.plans).length > 1 ? 's' : ''}`)}</div>}
            {!showMergedPlans && getActivePlans(device.plans).map((p, i) => <div key={i} className="flex flex-col border rounded-md relative group">
                <div className=" absolute right-1 top-1 invisible group-hover:visible">
                    <DeleteButton onClick={() => {
                        deletePlanMutation.mutate(
                            `deviceSerial=${device.deviceSerial}&planId=${p._id}`
                        )
                    }} />
                </div>
                <div className="flex">
                    <div className="flex flex-col p-2 border-r font-bold px-4">{getTimeFromCron(p)}</div>
                    <div className="flex px-4 items-center justify-center w-full">
                        <div className="flex gap-2">
                            {getActiveDaysFromCron(p).map((d, i) => <div key={i} className=" text-xs text-gray-500">{d}</div>)}
                        </div>
                    </div>
                </div>
                <div className="flex p-2 px-4 border-t gap-4 justify-center text-xs text-gray-500">
                    {p.durations.map((d, i) => <div key={i} className="">{d}</div>)}
                </div>
            </div>)}

            {showMergedPlans && getMergedPlans(getActivePlans(device.plans)).map((p, i) => <div key={i} className="flex flex-col border rounded-md relative group">
                <div className="flex">
                    <div className="flex flex-col p-2 border-r font-bold px-4">{getTimeFromCron(p)}</div>
                    <div className="flex px-4 items-center justify-center w-full">
                        <div className="flex gap-2">
                            {getActiveDaysFromCron(p).map((d, i) => <div key={i} className=" text-xs text-gray-500">{d}</div>)}
                        </div>
                    </div>
                </div>
                <div className="flex p-2 px-4 border-t gap-4 justify-center text-xs text-gray-500">
                    {p.durations.map((d, i) => <div key={i} className="">{d}</div>)}
                </div>
            </div>)}
            {

            }

        </div>
    </div >
}

export default ManagePlans