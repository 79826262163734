import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AppContainer from "../layout/AppContainer";
import { AppContext } from '../AppContext'
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import L from 'leaflet';
import { useQuery } from "react-query";
import axios from "axios";

// get hex color for signal strength
const getSignalStrengthColor = (rawValue) => {
    // 120 and above is red and 69 or below is green
    const value = -1 * parseInt(rawValue || "0")
    if (value >= 120) return '#ff0000';
    if (value <= 69) return '#00ff80';

    const ratio = (value - 69) / (120 - 69)
    const hue = ratio * 120 // go from green to red
    const hueInverse = 120 - hue
    const saturation = 100
    const lightness = 50
    return `hsl(${hueInverse}, ${saturation}%, ${lightness}%)`
}

const TextMarker = (props) => {
    const { text, position = [0, 0], popupText } = props;
    const textIcon = new L.divIcon({
        className: 'p-1 text-xs bg-white rounded-full shadow-md opacity-60 flex justify-center items-center',
        html: `<div><div style="height: 5px; width: 25px; background-color:${getSignalStrengthColor(text)}"></div>${text}</div>`,
        iconSize: [60, 20],
        iconAnchor: [0, 0],


    });
    return (
        <Marker position={position} icon={textIcon}>
            <Popup>{popupText}</Popup>
        </Marker>
    );
};

const ControlCenter = () => {
    const navigate = useNavigate();
    const { setToken } = useContext(AppContext)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        const token = localStorage.getItem("token");
        if (!token) {
            navigate("/login");
        } else {
            setToken(token)
        }
    }, []);

    const devices = useQuery('devicesSignalStrength', () => axios.get(process.env.REACT_APP_PLANNER_API_END_POINT + '/device/signal-strength'), {
        onSuccess: (data) => {
            console.log(data)
        }
    })

    return (
        <>
            <AppContainer>
                <div className=" rounded-md overflow-hidden mt-4">
                    <MapContainer
                        key="map"
                        center={[process.env.REACT_APP_MAP_CENTER_LAT, process.env.REACT_APP_MAP_CENTER_LNG]}
                        zoom={parseInt(process.env.REACT_APP_MAP_ZOOM)}
                        scrollWheelZoom={true}
                    >
                        {devices.data && devices.data.data.data.map((device) => {
                            return <TextMarker
                                text={device.signal}
                                popupText={device.sid}
                                position={[device.location.lat, device.location.lng]} >
                            </TextMarker>
                        })}
                        <TileLayer
                            opacity={0.5}
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                        />
                    </MapContainer>
                </div>
            </AppContainer>
        </>
    );
};

export default ControlCenter;
