import { combineReducers } from "redux";

import plansReducer from "./Plans";
import devicesReducer from "./Devices";
import authReducer from "./Auth";

const rootReducer = combineReducers({
  plans: plansReducer,
  devices: devicesReducer,
  auth: authReducer,
});

export default rootReducer;
