import { Loading3QuartersOutlined } from "@ant-design/icons";
import { notification, Switch } from "antd";
import axios from "axios";
import { useQuery, useMutation, useQueryClient } from "react-query";
import tr from '../i18n'

const NotificationStatus = ({ userId }) => {
    // query client
    const queryClient = useQueryClient();

    const noWateringSms = useQuery(["notificationStatusNoWateringSms", userId], () => {
        return axios.post(process.env.REACT_APP_PLANNER_API_END_POINT + `/notification-settings/status`, {
            userId: userId,
            type: "no_watering_sms"
        });

    });

    const lowWateringSms = useQuery(["notificationStatusLowWateringSms", userId], () => {
        return axios.post(process.env.REACT_APP_PLANNER_API_END_POINT + `/notification-settings/status`, {
            userId: userId,
            type: "low_watering_sms"
        });
    });

    const lowBatterySms = useQuery(["notificationStatusLowBatterySms", userId], () => {
        return axios.post(process.env.REACT_APP_PLANNER_API_END_POINT + `/notification-settings/status`, {
            userId: userId,
            type: "low_battery_sms"
        });
    });

    // use Mutation from react query to mutate status
    const updateNotificationStatus = useMutation(({ status, type }) => {
        return axios.put(process.env.REACT_APP_PLANNER_API_END_POINT + `/notification-settings/status`, {
            userId: userId,
            status: status,
            notificationType: type
        });
    }, {
        onSuccess: (data) => {
            notification.success({
                message: 'Başarılı',
                description: 'Bildiri gönderim ayarlarınız güncellendi',
            });
            queryClient.invalidateQueries(["notificationStatusNoWateringSms", userId]);
            queryClient.invalidateQueries(["notificationStatusLowWateringSms", userId]);
            queryClient.invalidateQueries(["notificationStatusLowBatterySms", userId]);
        },
        onError: (error) => {
            console.log(error);
            notification.error({
                message: 'Error',
                description: 'Failed to update notification status',
            });
        }
    });

    const handleSmsStatusChange = (checked, type) => {

        const status = checked ? 'active' : 'disabled';

        if (type === 'no_watering_sms') {
            // if noWateringSms status is same as the new status, do nothing
            if (noWateringSms.data.data.status === status) {
                return;
            }
        }

        if (type === 'low_watering_sms') {
            // if lowWateringSms status is same as the new status, do nothing
            if (lowWateringSms.data.data.status === status) {
                return;
            }
        }

        if (type === 'low_battery_sms') {
            // if lowWateringSms status is same as the new status, do nothing
            if (lowBatterySms.data.data.status === status) {
                return;
            }
        }

        updateNotificationStatus.mutate({ status, type });
    }

    return (
        <div className=" border rounded-md  flex ">

            <div className=" flex gap-2 align-middle border-r p-4">
                {noWateringSms.isLoading && <div className=" text-xs text-gray-500"><Loading3QuartersOutlined spin /></div>}
                {noWateringSms.isError && <div className=" text-xs text-gray-500">Failed to load</div>}
                {noWateringSms.isSuccess && <div>{tr('No watering SMS')}</div>}
                {noWateringSms.isSuccess && <Switch checked={noWateringSms.data.data.status === 'active'}
                    onChange={(e) => handleSmsStatusChange(e, 'no_watering_sms')}
                />}

            </div>
            <div className=" flex gap-2 align-middle border-r p-4">
                {lowWateringSms.isLoading && <div className=" text-xs text-gray-500"><Loading3QuartersOutlined spin /></div>}
                {lowWateringSms.isError && <div className=" text-xs text-gray-500">Failed to load</div>}
                {lowWateringSms.isSuccess && <div>{tr('Low watering SMS')}</div>}
                {lowWateringSms.isSuccess && <Switch checked={lowWateringSms.data.data.status === 'active'}
                    onChange={(e) => handleSmsStatusChange(e, 'low_watering_sms')}
                />}
            </div>
            <div className=" flex gap-2 align-middle p-4">
                {lowBatterySms.isLoading && <div className=" text-xs text-gray-500"><Loading3QuartersOutlined spin /></div>}
                {lowBatterySms.isError && <div className=" text-xs text-gray-500">Failed to load</div>}
                {lowBatterySms.isSuccess && <div>{tr('Low battery SMS')}</div>}
                {lowBatterySms.isSuccess && <Switch checked={lowBatterySms.data.data.status === 'active'}
                    onChange={(e) => handleSmsStatusChange(e, 'low_battery_sms')}
                />}
            </div>
        </div>
    );


}

export default NotificationStatus;