import React, { useEffect, useState } from "react";
import { ArrowLeftOutlined, InfoCircleOutlined, CloseOutlined } from "@ant-design/icons";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { HiOutlineDocumentReport } from "react-icons/hi";
import axios from "axios";
import { notification, Tooltip } from "antd";
import Modal from "antd/es/modal/Modal";
import PropTypes from "prop-types";


const SchedulerGardenInformation = (props) => {
  const [messages, setMessages] = useState([])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    console.log(props.notifications);
    // setMessages(props.notifications)
    setMessages(props.notifications.filter(n => n.type === 'watering' && n.gardenId === props.gardenId))
  }, [props.notifications])

  const closeMessage = (i) => {
    setMessages(p => p.filter((m, index) => i !== index))
  }

  const deviceReport = useQuery(["GardenReport", props.gardenId], () => {

    return axios.post(
      process.env.REACT_APP_PLANNER_API_END_POINT +
      "/garden/dashboard", {
      id: process.env.REACT_APP_GARDEN_WATERING_DASHBOARD,
      params: {
        gardenid: props.gardenId,
        relative_date: "yesterday",
        relative_date_1: "past7days",
        relative_date_2: "past30days",
      }
    }
    )
  }, { enabled: !!props.gardenId, refetchOnWindowFocus: false });

  const [reportModalVisible, setReportModalVisible] = useState(false)

  const openReport = () => {
    setReportModalVisible(true)
  }

  // states for property name and value
  const [propertyName, setPropertyName] = useState("");
  const [propertyValue, setPropertyValue] = useState("");

  // mutation to update garden geoJson
  const queryClient = useQueryClient();
  const insertRow = useMutation(
    ({ propertyName, propertyValue }) => {
      if (!propertyName || !propertyValue) return Promise.reject("Property name and value are required");

      const updatedGeoJson = {
        ...props.mapCenter,
        properties: {
          ...props.mapCenter.properties,
          [propertyName]: propertyValue,
        },
      };

      return axios.put(
        process.env.REACT_APP_PLANNER_API_END_POINT +
        "/garden", {
        id: props.gardenId,
        geoJson: updatedGeoJson,
      }
      )
    },
    {
      onSuccess: () => {
        // show success notification
        notification.success({
          message: "Başarılı",
          description: "Bahçe başarıyla güncellendi",
        });

        setPropertyName("");
        setPropertyValue("");
        queryClient.invalidateQueries("Garden");
      },
      onError: (error) => {
        // show error notification
        notification.error({
          message: "Hata",
          description: error.response.data.message,
        });
      }
    }
  );

  const deleteRow = useMutation(
    (key) => {
      if (!key) return Promise.reject("Key is required");

      if (["MAHAL_ID", "MAHAL_ADI"].includes(key)) return Promise.reject("Can not delete fixed property")

      const updatedGeoJson = {
        ...props.mapCenter,
        properties: {
          ...props.mapCenter.properties,
          [key]: undefined,
        },
      };

      return axios.put(
        process.env.REACT_APP_PLANNER_API_END_POINT +
        "/garden", {
        id: props.gardenId,
        geoJson: updatedGeoJson,
      }
      )
    },
    {
      onSuccess: () => {
        // show success notification
        notification.success({
          message: "Başarılı",
          description: "Bahçe başarıyla güncellendi",
        });

        setPropertyName("");
        setPropertyValue("");
        queryClient.invalidateQueries("Garden");
      },
      onError: (error) => {
        // show error notification
        notification.error({
          message: "Hata",
          description: error.response.data.message,
        });
      }
    }
  );



  return (
    <div className=" w-full">
      <div className="flex flex-row gap-2 mb-4 items-center">
        <button
          onClick={() => {
            props.navigate("/control-center/gardens");
          }}
          to="dashboard"
          className="px-3 p-1 rounded-md w-max bg-white bg-opacity-20 active flex items-center text-gray-800"
        >
          <ArrowLeftOutlined />
          <span className="ml-2">Geri</span>
        </button>
        {props.mapCenter.properties && (
          <div className="text-md text-gray-800 font-semibold flex flex-col items-start gap-1  flex-wrap">
            <div className="flex items-center cursor-pointer gap-1 hover:bg-white hover:bg-opacity-20 p-2 rounded-md" onClick={props.showModal} >
              {props.mapCenter.properties.MAHAL_ADI}
              <span className=" flex items-center gap-1 hover:text-blue-500 cursor-pointer" >
                <InfoCircleOutlined />
              </span>

            </div>

            {process.env.REACT_APP_GARDEN_WATERING_DASHBOARD !== "0" && <>
              <Tooltip title="Sulama Raporu">
                <div className="flex items-center font-normal bg-white bg-opacity-20 p-1 px-2 rounded-md cursor-pointer text-gray-700" onClick={() => openReport()}>
                  <HiOutlineDocumentReport />
                  <div className=" text-xs ">
                    Raporlar
                  </div>
                </div>
              </Tooltip>
              <Modal footer={null} title="Sulama Raporu" style={{ top: 0 }} width={"80vw"} height={"100vh"} visible={reportModalVisible} onOk={() => { setReportModalVisible(false) }} onCancel={() => { setReportModalVisible(false) }}>
                {deviceReport.isLoading && "Loading..."}

                {/* {deviceReport.isSuccess && JSON.stringify(deviceReport.data)} */}
                {deviceReport.isSuccess && <iframe
                  src={deviceReport.data.data.url}
                  frameborder="0"
                  width="100%"
                  height="800"
                  allowtransparency="true"
                ></iframe>}

              </Modal>
            </>}
          </div>
        )}

      </div>
      <div className="flex flex-col relative">
        <div className=" absolute flex flex-col gap-1 w-full z-10">
          {messages.map((m, i) => (<div key={i} className=" relative w-full bg-blue-500 bg-opacity-90 p-4 rounded-md text-white flex flex-col gap-2">
            <div onClick={() => closeMessage(i)} className=" absolute rounded-sm w-4 h-4 bg-white right-4 p-1 flex items-center justify-center text-blue-500"><CloseOutlined /></div>
            <div className=" text-blue-500 bg-white rounded-md p-1 px-2 text-xs w-max flex items-center gap-2">
              <div className="w-2 h-2">
                <div className="  absolute rounded-full bg-blue-500 w-2 h-2 animate-ping"></div>
                <div className="  absolute rounded-full bg-blue-500 w-2 h-2 "></div>
              </div>
              SULAMA
            </div>
            {m.name} cihazınız {m.planTime} dakika süreyle sulama yapacak.
          </div>)
          )}
        </div>

        {props.mapCenter.properties && (
          <div
            onClick={() => { }}
            className="w-full bg-white overflow-hidden rounded-md text-gray-700 flex flex-col"
          >
            <div
              className="w-full h-40 flex-shrink-0 bg-green-brand bg-cover "
              style={{ backgroundImage: `url(${props.gardenPic})` }}
            ></div>

          </div>
        )}
      </div>

      <Modal
        title="Detaylı Bilgi"
        visible={props.isModalVisible}
        onOk={props.handleOk}
        okText="Kapat"
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={props.handleCancel}
      >
        <div className="flex flex-col">
          {props.mapCenter.properties && (
            <div
              onClick={() => { }}
              className="w-full bg-white overflow-hidden rounded-md text-gray-700 flex flex-row rounded-b-none"
            >
              <div
                className="w-44 h-36 flex-shrink-0 bg-green-brand bg-cover m"
                style={{ backgroundImage: `url(${props.gardenPic})` }}
              ></div>
              <div className="flex flex-col p-4 border border-l-0 border-b-0  w-full flex-grow-1 overflow-hidden rounded-md rounded-b-none rounded-l-none">
                <div className="text-md font-semibold flex items-center mb-2">

                  <span className="">{props.gardenInfoTitle}</span>
                </div>

                <div className="flex flex-row items-center">
                  <span className="text-xs">
                    {props.mapCenter.properties.MALIKTIP}
                  </span>
                </div>
                <span className="my-1 flex flex-col">
                  <span className="text-xs  pr-2">
                    {props.mapCenter.properties.ESKI_MAHAL_ADI}
                  </span>
                  <span className="text-xs text-gray-500">
                    {props.mapCenter.properties["SHAPE.AREA"]}
                  </span>
                </span>
              </div>
            </div>
          )}
        </div>
        <div
          onClick={() => { }}
          className="w-full h-44 overflow-y-auto bg-white rounded-md rounded-t-none overflow-hidden border text-gray-700 flex flex-row box-border flex flex-col"
        >

          <div className="flex flex-col p-4 ">
            {props.mapCenter.properties && (
              <table className="table-auto w-full">
                <tbody>
                  {Object.keys(props.mapCenter.properties).map((k) => (
                    <tr className=" hover:bg-gray-100 group">
                      <td className="text-xs text-gray-500">{k}</td>
                      <td className="text-xs text-gray-500">
                        {props.mapCenter.properties[k]}
                      </td>
                      <td><button onClick={() => deleteRow.mutate(k)} className=" invisible group-hover:visible bg-gray-300 rounded-full w-4 h-4 flex items-center justify-center text-gray-400 hover:text-gray-500">x</button></td>
                    </tr>
                  ))}
                </tbody>
              </table>

            )}
          </div>
          <div className="  text-xs p-2 flex gap-1 items-center">
            <input value={propertyName} type="text" placeholder="Başlık" className=" border-blue-600 p-2 rounded-sm ring-1 ring-gray-300 h-6" onChange={(e) => setPropertyName(e.target.value)} />
            <input value={propertyValue} type="text" placeholder="Detay" className=" border-blue-600 p-2 rounded-sm ring-1 ring-gray-300  h-6" onChange={(e) => setPropertyValue(e.target.value)} />
            <button className="flex items-center justify-center px-2 border rounded-md bg-blue-600 hover:bg-opacity-80 text-white  h-7" onClick={() => insertRow.mutate({ propertyName, propertyValue })} >+ Ekle</button>
          </div>
        </div>
      </Modal>
    </div >
  );
};

SchedulerGardenInformation.propTypes = {
  navigate: PropTypes.func,
  mapCenter: PropTypes.object,
  gardenPic: PropTypes.any,
  gardenInfoTitle: PropTypes.string,
  showModal: PropTypes.func,
  isModalVisible: PropTypes.bool,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
};

export default SchedulerGardenInformation;
