import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import ReactJson from 'react-json-view'
import axios from "axios";
import { useQuery } from "react-query"
import tr from '../i18n'
import { useState } from "react";
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)

const Audit = (props) => {
    const ACTIONS_MAP = {
        '/api/device': {
            POST: { name: 'Create device', },
            PUT: { name: 'Update device' },
            DELETE: { name: 'Delete device' },
        },
        '/api/device/location': {
            PUT: { name: 'Update device location' },
        },
        '/api/garden': {
            POST: { name: 'Create garden' },
            PUT: { name: 'Update garden' },
        },
        '/api/garden/device': {
            PUT: { name: 'Assign device' },
            DELETE: { name: 'Remove device' },
        },
        '/api/garden/commit-schedules': {
            POST: { name: 'Commit scheduler plans' },
        },
        '/api/garden/user': {
            PUT: { name: 'Assign user' },
            DELETE: { name: 'Unassign user' },
        },
        '/api/garden/tags': {
            PUT: { name: 'Tag garden' },
            DELETE: { name: 'Untag garden' },
        },
        '/api/garden/active-templates': {
            PUT: { name: 'Activate template' },
        },
        '/api/plan': {
            POST: { name: 'Create plan' },
            DELETE: { name: 'Delete plan' },
        },
        '/api/plan/test/watering': {
            POST: { name: 'Test watering' },
        },
        '/api/quick-plan-templates': {
            POST: { name: 'Create quick plan template' },
            DELETE: { name: 'Delete quick plan template' }
        },
        '/api/tag': {
            POST: { name: 'Create tag' },
            DELETE: { name: 'Delete tag' },
        }
    }

    const [gardenId, setGardenId] = useState(null)

    const getAction = (url, method) => {
        const correctedUrl = url.split('?')[0].replace(/\/$/, '')
        if (ACTIONS_MAP[url] && ACTIONS_MAP[correctedUrl][method]) {
            return ACTIONS_MAP[correctedUrl][method]
        } else {
            return correctedUrl
        }
    }

    const getComponent = (obj, name) => {
        switch (name) {
            // case 'Test watering':
            //     return <div>
            //         Device: {obj.}
            //     </div>

            default:
                return <ReactJson collapsed src={obj} />
        }
    }

    const auditRes = useQuery(['GetAuditTrail', gardenId], () => {
        return axios.get(process.env.REACT_APP_PLANNER_API_END_POINT + `/audit?gardenId=${gardenId}`)
    })

    return <div className="bg-white border text-gray-800 rounded-md flex flex-row rounded-tl-none">
        <div className="flex flex-col w-full">
            <div className="p-4">
                <Input.Search onSearch={(a) => { setGardenId(a) }} style={{ borderRadius: 5 }} prefix={<SearchOutlined />} placeholder="Search by garden ID" allowClear />
            </div>
            <div className="p-4 flex flex-col gap-2 h-screen overflow-y-auto">
                {auditRes.isSuccess && auditRes.data.data.data.map(a => < div key={a._id} className="flex flex-col">
                    <div className="p-1 px-2 bg-gray-100 rounded-t-md text-xs flex gap-2 justify-between">
                        <div className="flex flex-col">
                            <div className=" text-sm">{tr(getAction(a.originalUrl, a.method).name)}</div>
                            <div className="text-gray-400">{dayjs(a.createdAt).format('DD/MM HH:mm:ss')}</div>
                        </div>
                        <div className=" flex flex-col items-end justify-center gap-1">
                            <div className="px-2 bg-blue-500 rounded-md text-xs text-white">{tr(a?.user?.role)} </div>{a?.user?.name}
                        </div>

                    </div>
                    <div className="flex gap-2 border rounded-b-md p-1 px-2 flex-col">

                        {getComponent(a, getAction(a.originalUrl, a.method).name)}
                    </div>
                </div>)}
            </div>
        </div>
    </div >
}

export default Audit;