import axios from "axios";

/*

axios response will be like

axios.get('/user/12345')
  .then(function (response) {
    console.log(response.data);
    console.log(response.status);
    console.log(response.statusText);
    console.log(response.headers);
    console.log(response.config);
  });

*/

export const DayPilotService = {
  createPlan(payload) {
    return axios.post(
      `${process.env.REACT_APP_PLANNER_API_END_POINT}/plan`,
      payload
    );
  },

  getPlans(serialNumber) {
    return axios.get(
      `${process.env.REACT_APP_PLANNER_API_END_POINT}/plan?deviceSerial=${serialNumber}`
    );
  },

  getPlansByDayInformation(serialNumber, days) {
    return axios.get(
      `${process.env.REACT_APP_PLANNER_API_END_POINT}/plan?deviceSerial=${serialNumber}&days=${days}`
    );
  },

  updatePlan(payload) {
    return axios.put(
      `${process.env.REACT_APP_PLANNER_API_END_POINT}/plan`,
      payload
    );
  },

  deletePlan(payload) {
    return axios.delete(
      `${process.env.REACT_APP_PLANNER_API_END_POINT}/plan?deviceSerial=${payload.deviceSerial}&planId=${payload.planId}`
    );
  },

  updateGardenWateringDay(payload) {
    return axios.put(
      `${process.env.REACT_APP_PLANNER_API_END_POINT}/garden`,
      payload
    );
  },
};
