const ROLES = {
    SUPER_ADMIN: 'super_admin',
    TECHNICAL_ADMIN: 'technical_admin',
    GARDEN_TESTER: 'garden_tester',
    REGIONAL_MANAGER: 'regional_manager',
    FIELD_MANAGER: 'field_manager',
    PRO_USER: 'pro_user',
    END_USER: 'end_user',
    DEMO: 'demo',
};

const ACCESS_LEVEL = {
    'super_admin': 0,
    'technical_admin': 1,
    'regional_manager': 2,
    'field_manager': 3,
    'pro_user': 1,
    'end_user': 5,
};

const hasAccess = (intendedRole, currentRole) => {
    if (!currentRole) return false;

    return ACCESS_LEVEL[currentRole] <= ACCESS_LEVEL[intendedRole]
}

const hasRoles = (intendedRoles, currentRole) => {
    if (!currentRole) return false;

    return intendedRoles.includes(currentRole)
}

export {
    ROLES,
    hasAccess,
    hasRoles
}