function SignalStrengthBars(props) {
  const { value, darkMode, size } = props;

  const bars = [];
  const barSize = size === "small" ? 3 : 5;
  const barStep = size === "small" ? 1 : 2;
  const barMargin = size === "small" ? 1 : 2;

  for (let i = 0; i < 5; i++) {
    bars.push(<div
      className={i < value ? `${darkMode ? ' bg-gray-200' : 'bg-gray-700'}` : `${darkMode ? ' bg-gray-500' : 'bg-gray-300'}`}
      key={i}
      style={{
        width: "3px", height: `${barSize + i * barStep}px`,
        marginRight: barMargin,
        borderRadius: 5
      }} />);
  }
  return (
    <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-end" }}>
      {bars}
    </div>
  );
}

export default SignalStrengthBars;







