import { useEffect, useState, useContext } from "react";
import { Checkbox, Avatar, Input, Transfer, notification, Button, Popover, Select, Modal } from "antd";
import {
  SearchOutlined,
} from "@ant-design/icons";
import { AiOutlineDesktop, AiOutlineMobile, AiOutlineTablet } from "react-icons/ai";
import { BsShieldLockFill, BsWindows, BsApple, BsBrowserChrome, BsBrowserSafari, BsBrowserFirefox, BsBrowserEdge, BsAndroid, BsRobot } from "react-icons/bs";
import axios from "axios";
import { transformTurkish } from "../utils/RegExUtility"
import { AppContext } from '../AppContext'
import { hasAccess, ROLES } from '../utils/AccessLevels'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import tr from '../i18n'
import useWindowSize from "../utils/WindowSize"
import InfoLabel from "../components/InfoLable";
import NotificationStatus from "../components/NotificationStatus";
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
const { Option } = Select;
dayjs.extend(customParseFormat)


const UserAccess = () => {
  const [usersResult, setUsersResult] = useState([])
  const [userSearchQuery, setUserSearchQuery] = useState("")
  const [selectedUser, setSelectedUser] = useState(null)
  const [targetKeys, setTargetKeys] = useState([]);
  const [removeConfirmationVisible, setRemoveConfirmationVisible] = useState(false)

  const [loading, setLoading] = useState(false)
  const [tags, setTags] = useState([])
  const [selectedTags, setSelectedTags] = useState([])
  const { gardens, fetchAllGardens, role } = useContext(AppContext);
  const queryClient = useQueryClient()
  const { height } = useWindowSize()


  const users = useQuery('FetchAllUsers', async () => {
    const res = await axios.get(process.env.REACT_APP_GARDENER_API_END_POINT + '/user')
    return res.data.data
  })

  useEffect(() => {

    const fetchGardenTags = async () => {
      try {
        // setLoadingGardens(true)
        const res = await axios.get(process.env.REACT_APP_PLANNER_API_END_POINT + '/tag?resource=gardens')
        setTags(res.data.tags)
      } catch (error) {
        console.log(error);
      } finally {
        // setLoadingGardens(false)
      }
    }


    fetchAllGardens()
    fetchGardenTags()
  }, [])

  useEffect(() => {
    const fetchUserGardens = async () => {
      if (!selectedUser) return

      try {
        setLoading(true)
        const res = await axios.get(`${process.env.REACT_APP_PLANNER_API_END_POINT}/garden/user/${selectedUser.id}`)
        console.log(res);
        const gardenIds = res.data.gardens.map(g => g.id)
        console.log(gardenIds);
        setTargetKeys(gardenIds)
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false)
      }
    }

    fetchUserGardens()
  }, [selectedUser])

  const userSessions = useQuery(['UserSessions', selectedUser], () => {
    return axios.get(process.env.REACT_APP_GARDENER_API_END_POINT + '/auth/sessions/' + selectedUser.id)
  }, { enabled: hasAccess(ROLES.SUPER_ADMIN, role) })

  const onTargetsChange = async (nextTargetKeys, direction, moveKeys) => {
    console.log(nextTargetKeys, direction, moveKeys);
    const userId = selectedUser.id
    if (direction === 'right') {
      // adding gardens : moveKeys
      try {
        setLoading(true)

        const res = await axios.put(`${process.env.REACT_APP_PLANNER_API_END_POINT}/garden/user`, {
          userId,
          gardenIds: moveKeys
        })
        if (res.data.success) {
          notification.success({
            message: "Başarılı",
            description: `Seçili bahçeler kullanıcıya eklendi`,
            placement: "topRight",
          });

          // if successful set target keys
          setTargetKeys(nextTargetKeys)
        } else {
          notification.error({
            message: tr("Something went wrong"),
            description: tr(`While assigning gardens`),
            placement: "topRight",
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false)

      }


    }

    if (direction === 'left') {
      // removing gardens : moveKeys

      try {
        setLoading(true)
        const res = await axios.delete(`${process.env.REACT_APP_PLANNER_API_END_POINT}/garden/user`, {
          data: {
            userId,
            gardenIds: moveKeys
          }
        })

        if (res.data.success) {
          notification.success({
            message: "Başarılı",
            description: `Seçilen bahçeler kullanıcıdan kaldırıldı`,
            placement: "topRight",
          });

          // if successful set target keys
          setTargetKeys(nextTargetKeys)
        } else {
          notification.error({
            message: tr("Something went wrong"),
            description: tr(`While removing gardens`),
            placement: "topRight",
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false)

      }
    }

  };

  const onGardenSearch = (dir, value) => { };

  const onUserClick = (user) => {
    setSelectedUser(user)
  }

  const getNameFromEmail = (email) => email.split('@')[0]

  const filterOption = (input, item) => {
    return RegExp(`.*${transformTurkish(input)}.*`, "gi").test(
      transformTurkish(item.geoJson.properties.MAHAL_ADI))
  }

  const filteredGardens = () => {
    if (selectedTags.length > 0) {
      return gardens.filter(g => selectedTags.every(t => g.tags && g.tags.includes(t)))
    }

    return gardens
  }

  const onUserSearch = (e) => {
    console.log(e.target.value);
    const value = e.target.value
    setUserSearchQuery(value)
    const result = users.data.filter(u => RegExp(`.*${transformTurkish(value.toLowerCase())}.*`, "gi").test(
      transformTurkish(u.email.toLowerCase() + u.phone.toLowerCase() + (u.name || '').toLowerCase())))
    setUsersResult(result)
  }

  const onTagsChange = (e) => {
    setSelectedTags(e)
  }

  /**
   * Delete user
   */
  const deleteUser = useMutation((userId) => {
    return axios.delete(
      `${process.env.REACT_APP_GARDENER_API_END_POINT}/user`,
      { data: { userId } }
    )
  }, {
    onSuccess: () => {
      notification.success({
        message: tr("Successfully removed user"),
        description: ``,
        placement: "topRight",
      });

      queryClient.invalidateQueries('FetchAllUsers')
    },
    onError: () => {
      notification.error({
        message: tr("Failed to remove user"),
        description: ``,
        placement: "topRight",
      });
    },
    onSettled: () => {
      setRemoveConfirmationVisible(false)
    }
  })

  const [sessionManagementVisible, setSessionManagementVisible] = useState(false)

  const getOs = (ua) => {
    if (!ua) return "Legacy|"

    switch (ua.os.name) {
      case "Windows":
        return <BsWindows />
        break;

      case "OSX":
        return <BsApple />
        break;

      case "IOS":
        return <BsApple />
        break;

      case "Android":
        return <BsAndroid />
        break;


      default:
        return ua.os.name
        break;
    }
  }

  const getBrowser = (ua) => {
    if (!ua) return "N/A"

    switch (ua.browser.name) {
      case "Chrome":
        return <BsBrowserChrome />
        break;

      case "Safari":
        return <BsBrowserSafari />
        break;

      case "Firefox":
        return <BsBrowserFirefox />
        break;

      case "Edge":
        return <BsBrowserEdge />
        break;

      default:
        return ua.browser.name
        break;
    }
  }

  const getDeviceType = (ua) => {
    if (!ua) return "N/A"

    if (ua.isMobile) {
      return <AiOutlineMobile />
    }

    if (ua.isTablet) {
      return <AiOutlineTablet />
    }

    if (ua.isDesktop) {
      return <AiOutlineDesktop />
    }

    if (ua.isBot) {
      return <BsRobot />
    }
  }

  const logoutUser = useMutation(({ id, iat, ua }) => {
    return axios.post(
      `${process.env.REACT_APP_GARDENER_API_END_POINT}/auth/logout`,
      {
        id,
        iat,
        ua
      }
    )
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(['UserSessions', selectedUser]);
      notification.success({
        message: tr("User logged out successfully"),
      })
    },
    onError: (error) => {
      notification.error({
        message: tr("Something went wrong while logging out user"),
      })
    },
  })


  return (
    <>
      <div className="bg-white border text-gray-800 rounded-md flex flex-row rounded-tl-none">
        <div className="flex flex-col " style={{ height: height }}>
          <div className="p-4 border-b relative">
            <Input style={{ borderRadius: 5 }} onChange={onUserSearch} prefix={<SearchOutlined />} placeholder="Kullanıcı ara" allowClear />
          </div>
          {users.isLoading && <><div className="p-2 animate-pulse flex flex-row">
            <div className="bg-gray-200 h-5 w-5 mr-2 rounded-full"></div>
            <div className="bg-gray-200 h-5 w-full rounded-md"></div>
          </div>
            <div className="p-2 animate-pulse flex flex-row">
              <div className="bg-gray-200 h-5 w-5 mr-2 rounded-full"></div>
              <div className="bg-gray-200 h-5 w-full rounded-md"></div>
            </div></>}
          <div className=" w-full h-full overflow-auto">
            {users.isSuccess && (userSearchQuery ? usersResult : users.data).map(user => <div onClick={() => onUserClick(user)} className="flex flex-row gap-4 items-center hover:bg-gray-100 p-4 border-b cursor-pointer">
              <div className="">
                <Avatar>{user.id}</Avatar>
              </div>
              <div className="">
                <span className="block font-bold">{user.name || getNameFromEmail(user.email) || user.phone}</span>
                <span className="text-xs block">{user.email || user.phone}</span>
              </div>
            </div>)}
          </div>
        </div>
        <div className=" border-l flex flex-col w-full">
          {selectedUser && <div className="flex flex-row gap-4 items-center p-4 border-b w-full flex-wrap">
            <div className="">
              <Avatar>{selectedUser.id}</Avatar>
            </div>
            <div className="">
              <div className="block whitespace-nowrap font-bold text-lg">{selectedUser.name || selectedUser.email || selectedUser.phone}</div>
              <div className="text-xs flex items-start gap-1 flex-col ">
                <div>{selectedUser.email || selectedUser.phone}</div>{" "}
                {hasAccess(ROLES.SUPER_ADMIN, role) && <><button onClick={() => setSessionManagementVisible(true)} className="text-green-brand flex items-center px-2 py-1 border gap-1 rounded-md hover:bg-gray-100">
                  <BsShieldLockFill /> Oturumları yönet
                </button>
                  <Modal visible={sessionManagementVisible} footer={false} onCancel={() => setSessionManagementVisible(false)} onOk={() => setSessionManagementVisible(false)}>
                    <div className="flex flex-col gap-2">
                      {userSessions.data && userSessions.data.data && userSessions.data.data.sessions.length === 0 && <div>Aktif oturum yok</div>}

                      {userSessions.data && userSessions.data.data && userSessions.data.data.sessions.length !== 0 && <table>
                        <tr className=" text-xs text-gray-400">
                          <td className=" pb-2">OS/Browser</td>
                          <td className=" pb-2">{tr("Created on")}</td>
                          <td className=" pb-2"></td>
                        </tr>
                        {userSessions.data.data.sessions.map(s => <tr key={s.id + s.iat} className=" hover:bg-gray-100 p-1">
                          <td className="flex gap-1 items-center">
                            <div className=" text-2xl">
                              {getDeviceType(s.ua)}
                            </div>
                            <div className=" flex gap-1 text-xs text-blue-300">{getOs(s.ua)} {getBrowser(s.ua)}</div>
                          </td>
                          <td className=" text-xs">
                            {dayjs(new Date(s.iat * 1000)).format('DD/MM/YYYY HH:mm:ss')}
                          </td>
                          <td> <button onClick={() => logoutUser.mutate(s)} className=" p-1 px-3 text-red-600  rounded-md text-xs">{tr("Logout")}</button></td>
                        </tr>)}
                      </table>}
                    </div>
                  </Modal></>}
              </div>
            </div>
            {selectedUser && <div className="p-4 border rounded-md flex items-center gap-1">
              <div className="text-xs">
                <div className="font-semibold whitespace-nowrap">{tr(selectedUser.role)}</div>

              </div>
            </div>}
            {hasAccess(ROLES.TECHNICAL_ADMIN, role) && <div className="flex flex-row-reverse">
              <Popover placement="left" visible={removeConfirmationVisible} onVisibleChange={(v) => setRemoveConfirmationVisible(v)} content={
                <div className="p-3 flex flex-col items-center">
                  Bu kullanıcıyı kaldırmak istediğinizden emin misiniz?
                  <div>
                    <Button type="link" onClick={() => { deleteUser.mutate(selectedUser.id) }}>Evet</Button> <Button type="link" onClick={() => setRemoveConfirmationVisible(false)}>Hayır</Button>
                  </div>
                </div>} trigger="click">
                <Button size="small" type="link" danger onClick={() => setRemoveConfirmationVisible(true)}>{tr("Delete the user")}</Button>
              </Popover>
            </div>}
          </div>}
          <div className="flex gap-4 p-4 px-6 flex-wrap">

            {!selectedUser && <div className="flex p-4 border rounded-md  gap-4 ">
              <InfoLabel>Lütfen bir kullanıcı seçin</InfoLabel>
            </div>}

            <div className="w-full  pb-4 mt-0">
              <span className="block font-bold">Park/Bahçe Erişim</span>

            </div>
            {selectedUser &&
              <div className="flex flex-col gap-1">
                <InfoLabel> {tr('Notification SMS status')}</InfoLabel>

                <NotificationStatus userId={selectedUser.id} />
              </div>
            }
            {!selectedUser && <div className="flex p-4 border rounded-md  gap-4 ">
              <InfoLabel> Lütfen bir kullanıcı seçin</InfoLabel>
            </div>}
            <div className="w-full flex flex-row">
              {loading && <div className="w-full flex flex-col gap-5 animate-pulse">

                <div className="w-full flex flex-row gap-5">
                  <div className="flex flex-col w-1/3 rounded-md gap-5">
                    <div className="bg-gray-200 h-5 rounded-md"></div>
                    <div className="bg-gray-200 h-32 rounded-md"></div>
                  </div>
                  <div className="flex flex-col w-1/3 rounded-md gap-5">
                    <div className="bg-gray-200 h-5 rounded-md"></div>
                    <div className="bg-gray-200 h-32 rounded-md"></div>
                  </div>
                </div>
              </div>}
              {selectedUser && !loading &&
                <div className="flex flex-col gap-3">
                  <div className="flex flex-col gap-1">
                    <InfoLabel>{tr("Filter gardens by tags")}</InfoLabel>
                    <Select mode="tags" placeholder={tr("Filter gardens by tags")} onChange={onTagsChange}>
                      {tags.map(t => <Option key={t.id}>{t.name}</Option>)}
                    </Select>
                  </div>

                  <Transfer

                    dataSource={filteredGardens()}
                    showSearch
                    filterOption={filterOption}
                    targetKeys={targetKeys}
                    onChange={onTargetsChange}
                    onSearch={onGardenSearch}
                    render={(item) => item.name}
                    rowKey={(r) => r.id}
                    listStyle={{ width: 400, height: 600, borderRadius: 5 }}
                    locale={{ itemUnit: 'adet', itemsUnit: 'adet' }}
                    titles={[
                      <div className="text-gray-800 font-bold text-xs">
                        Uygun
                        <span className="text-gray-500 text-xs block font-normal">
                          Atanabilir Parklar/Bahçeler
                        </span>
                      </div>,
                      <div className="text-gray-800 font-bold text-xs">
                        Atanan
                        <span className="text-gray-500 text-xs block font-normal">
                          Kullanıcıya Atanan Parklar/Bahçeler
                        </span>
                      </div>,
                    ]}
                  />
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserAccess;
