import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";


const HeaderLogoArea = (props) => {
  const navigate = useNavigate();
  const toDashboard = () => { navigate(process.env.REACT_APP_SHOW_DASHBOARD === "true" ? "/control-center/dashboard" : "/control-center/gardens"); }
  return (
    <div onClick={toDashboard} className="flex flex-row items-center cursor-pointer">
      <div className=" rounded-md overflow-hidden">
        <img className=" h-16" src={props.logo} alt="" />
      </div>
    </div>
  );
};

HeaderLogoArea.propTypes = {
  logo: PropTypes.any,
  logoText: PropTypes.any,
};

export default HeaderLogoArea;
