import React from "react";
import PropTypes from "prop-types";
import { BASE_MAPS } from "../utils/MapUtils";

const GardenListMapFunctionalBox = (props) => {
  return (
    <div
      className="absolute top-1 right-1 bg-white rounded-md p-4 text-gray-800 text-xs text-right "
      style={{ zIndex: 9999999 }}
    >
      <div className="mb-2">
        <button
          className="p-1 border rounded-l"
          onClick={() => {
            props.setStreetMap(true);
            props.setSelectedTileUrl(BASE_MAPS[0].value);
          }}
        >
          Sokak Görünümü
        </button>
        <button
          className="p-1 border rounded-r"
          onClick={() => {
            props.setStreetMap(false);
            props.setSelectedTileUrl(BASE_MAPS[2].value);
          }}
        >
          Uydu
        </button>
      </div>
      {props.mouseOverFeature && (
        <>
          <span>{props.mouseOverFeature.properties.MAHAL_ADI}</span>
          <br />
          <span className="mt-1 text-gray-400">
            {props.mouseOverFeature.properties.MAHAL_ID}
          </span>
        </>
      )}
    </div>
  );
};

GardenListMapFunctionalBox.propTypes = {
  setStreetMap: PropTypes.func,
  mouseOverFeature: PropTypes.object,
  setSelectedTileUrl: PropTypes.func,
};

export default GardenListMapFunctionalBox;
