import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DayPilotService } from "../../shared/services/DayPilotService";

export const initialState = {
  plans: [],
  loading: false,
  hasErrors: false,
  createPlanResponse: null,
  updatePlanResponse: null,
  deletePlanResponse: null,
  updateGardenWateringDayResponse: null,
};

const plansSlice = createSlice({
  name: "plans",
  initialState,
  reducers: {
    getPlansDayByDay: (state) => {
      state.loading = true;
    },
    getPlansDayByDaySuccess: (state, { payload }) => {
      state.plans = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getPlansDayByDayFailure: (state) => {
      state.loading = false;
      state.hasLoading = true;
    },
    createPlan: (state) => {
      state.loading = true;
    },
    createPlanSuccess: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = false;
      state.createPlanResponse = payload;
    },
    createPlanFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    updatePlan: (state) => {
      state.loading = true;
    },
    updatePlanSuccess: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = false;
      state.updatePlanResponse = payload;
    },
    updatePlanFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    deletePlan: (state) => {
      state.loading = true;
    },
    deletePlanSuccess: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = false;
      state.deletePlanResponse = payload;
    },
    deletePlanFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    updateGardenWateringDay: (state) => {
      state.loading = true;
    },
    updateGardenWateringDaySuccess: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = false;
      state.updateGardenWateringDayResponse = payload;
    },
    updateGardenWateringDayFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
});

export const {
  getPlansDayByDay,
  getPlansDayByDaySuccess,
  getPlansDayByDayFailure,
  createPlan,
  createPlanSuccess,
  createPlanFailure,
  updatePlan,
  updatePlanFailure,
  updatePlanSuccess,
  deletePlan,
  deletePlanSuccess,
  deletePlanFailure,
  updateGardenWateringDay,
  updateGardenWateringDaySuccess,
  updateGardenWateringDayFailure,
} = plansSlice.actions;

export const plansSelector = (state) => state.plans;

export default plansSlice.reducer;

export function fetchPlansDayByDay(serialNumber, day) {
  return async (dispatch) => {
    dispatch(getPlansDayByDay());

    try {
      const response = await DayPilotService.getPlansByDayInformation(
        serialNumber,
        day
      );
      const data = (await response.data?.plans?.plans) || [];
      dispatch(getPlansDayByDaySuccess(data));
    } catch (error) {
      dispatch(getPlansDayByDayFailure());
    }
  };
}

export const getAllPlansForSelectedDay = createAsyncThunk(
  "getAllPlansForSelectedDay",
  (request, thunkAPI) => {
    thunkAPI.dispatch(getPlansDayByDay());
    return DayPilotService.getPlansByDayInformation(
      request.serialNumber,
      request.day
    )
      .then((response) => {
        return response?.data
          ? thunkAPI.dispatch(getPlansDayByDaySuccess(response?.data?.plans?.plans[0].times || []))
          : thunkAPI.dispatch(getPlansDayByDayFailure());
      })
      .catch((err) => thunkAPI.dispatch(getPlansDayByDayFailure()));
  }
);

export const inquireCreatePlan = createAsyncThunk(
  "createPlan",
  (request, thunkAPI) => {
    thunkAPI.dispatch(createPlan());
    return DayPilotService.createPlan(request)
      .then((response) => {
        return response?.data
          ? thunkAPI.dispatch(createPlanSuccess(response.data || null))
          : thunkAPI.dispatch(createPlanFailure());
      })
      .catch((err) => thunkAPI.dispatch(createPlanFailure()));
  }
);

export const inquireUpdatePlan = createAsyncThunk(
  "updatePlan",
  (request, thunkAPI) => {
    thunkAPI.dispatch(updatePlan());
    return DayPilotService.updatePlan(request)
      .then((response) => {
        return response?.data
          ? thunkAPI.dispatch(updatePlanSuccess(response?.data || null))
          : thunkAPI.dispatch(updatePlanFailure());
      })
      .catch((err) => thunkAPI.dispatch(updatePlanFailure()));
  }
);

export const inquireDeletePlan = createAsyncThunk(
  "deletePlan",
  (request, thunkAPI) => {
    thunkAPI.dispatch(deletePlan());
    return DayPilotService.deletePlan(request)
      .then((response) => {
        return response?.data
          ? thunkAPI.dispatch(deletePlanSuccess(response?.data || null))
          : thunkAPI.dispatch(deletePlanFailure());
      })
      .catch((err) => deletePlanFailure());
  }
);

export const updateGardenWateringDayCall = createAsyncThunk(
  "updateGardenWateringDay",
  (request, thunkAPI) => {
    thunkAPI.dispatch(updateGardenWateringDay());
    return DayPilotService.updateGardenWateringDay(request)
      .then((response) => {
        response?.data
          ? thunkAPI.dispatch(
              updateGardenWateringDaySuccess(response?.data || null)
            )
          : thunkAPI.dispatch(updateGardenWateringDayFailure());
      })
      .catch((err) => thunkAPI.dispatch(updateGardenWateringDayFailure()));
  }
);
