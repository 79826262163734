const translations = {
        en: {
                'Start': 'Start',
                'End': 'End',
                'Zone': 'Zone',
                'Active days': 'Active days',
                'Device Serial': 'Device Serial',
                'Last updated time': 'Last updated time',
                'Will remove stored quick plan information': 'Will remove stored quick plan information',
                'Are you sure?': 'Are you sure?',
                'Will remove stored per day information': 'Will remove stored per day information',
                'There are unsaved changes to the watering schedule': 'There are unsaved changes to the watering schedule',
                'Select from quick plan templates or create your own.': 'Select from quick plan templates or create your own.',
                'Create quick plan templates': 'Create quick plan templates',
                'Each zone will be watered for selected number of minutes on selected days': 'Each zone will be watered for selected number of minutes on selected days',
                'Start at': 'Start at',
                'Water for': 'Water for',
                'Create': 'Create',
                'Loading schedule data': 'Loading schedule data',
                'technical_admin': 'technical_admin',
                'hour': 'hour',
                'day': 'day',
                'minute': 'minute',
                'Total': 'Total',
                'Quick': 'Quick',
                'Advanced': 'Advanced',
                'Common': 'Common',
                'Per day': 'Per day',
                'Save': 'Save',
                'Mo': 'Mo',
                'Tu': 'Tu',
                'We': 'We',
                'Th': 'Th',
                'Fr': 'Fr',
                'Sa': 'Sa',
                'Su': 'Su',
                'Name': 'Name',
                'Filter gardens by tags': 'Filter gardens by tags',
                'Sun optimization': 'Sun optimization',
                'Rain optimization': 'Rain optimization',
                'Wind optimization': 'Wind optimization',
                'Smart watering enabled': 'Smart watering enabled',
                'Something went wrong while enabling smart watering': 'Something went wrong while enabling smart watering',
                'Yes': 'Yes',
                'No': 'No',
                'Test started': 'Test started',
                'Test stopped': 'Test stopped',
                'Gardens assigned successfully': 'Gardens assigned successfully',
                'Faulty phone number': 'Faulty phone number',
                'View': 'View',
                'Unassign': 'Unassign',
                'Stop': 'Stop',
                'Start test': 'Başlat',
                'Successfully removed user': 'Successfully removed user',
                'Garden is in test mode': 'Garden is in test mode',
                'Test mode': 'Test mode',
                'Test zone watering': 'Test zone watering',
                'Device mode': 'Device mode',
                'TEST': 'TEST',
                'PSM': 'PSM',
                'NORMAL': 'NORMAL',
                'User created successfully': 'User created successfully',
                'Invitation has been sent to the user': 'Invitation has been sent to the user',
                'Delete the user': 'Delete the user',
                'Changes saved successfully': 'Changes saved successfully',
                'Something went wrong while saving changes': 'Something went wrong while saving changes',
                'Test state changed successfully': 'Test state changed successfully',
                'Failed to change test state': 'Failed to change test state',
                'Device unassigned successfully': 'Device unassigned successfully',
                'Failed to unassign device': 'Failed to unassign device',
                'Failed to send test command': 'Failed to send test command',
                'Device assignments successful': 'Device assignments successful',
                'devices assigned': 'devices assigned',
                'Something went wrong': 'Something went wrong',
                'While assigning devices': 'While assigning devices',
                'Device removal successful': 'Device removal successful',
                'devices removed from garden': 'devices removed from garden',
                'While removing devices': 'While removing devices',
                'Tag created successfully': 'Tag created successfully',
                'While assigning gardens': 'While assigning gardens',
                'Gardens removal successful': 'Gardens removal successful',
                'gardens removed from  selected tags': 'gardens removed from  selected tags',
                'While removing gardens': 'While removing gardens',
                'User creation failed': 'User creation failed',
                'Failed to remove user': 'Failed to remove user',
                'Zoom level': 'Dakika',
                'Last online': 'Last online',
                'Garden ID and Name is required': 'Garden ID and Name is required',
                'Map area polygon is required': 'Map area polygon is required',
                'Test all valves (5 mins)': 'Test all valves (5 mins)',
                'Heat': 'Heat',
                'Rain probability': 'Rain probability',
                'Wind speed': 'Wind speed',
                'Delete the garden': 'Delete the garden',
                'Are you sure you want to remove this garden?': 'Are you sure you want to remove this garden?',
                'Water meter enabled': 'Water meter enabled',
                'Watering': 'Watering',
                'Add time': 'Add time',
                'Signal': 'Signal',
                'Battery': 'Battery',
                'Create device': 'Create device',
                'Update device': 'Update device',
                'Delete device': 'Delete device',
                'Update device location': 'Update device location',
                'Create garden': 'Create garden',
                'Update garden': 'Update garden',
                'Assign device': 'Assign device',
                'Remove device': 'Remove device',
                'Commit scheduler plans': 'Commit scheduler plans',
                'Assign user': 'Assign user',
                'Unassign user': 'Unassign user',
                'Tag garden': 'Tag garden',
                'Untag garden': 'Untag garden',
                'Activate template': 'Activate template',
                'Create plan': 'Create plan',
                'Delete plan': 'Delete plan',
                'Test watering': 'Test watering',
                'Create quick plan template': 'Create quick plan template',
                'Delete quick plan template': 'Delete quick plan template',
                'Create tag': 'Create tag',
                'Delete tag': 'Delete tag',
                'No watering SMS': 'No watering SMS',
                'Low watering SMS': 'Low watering SMS',

        },
        tr: {
                'Start': 'Başlangış',
                'End': 'Bitiş',
                'Zone': 'Bölge',
                'Active days': 'Sulama Günleri',
                'Device Serial': 'Cihaz Seri No',
                'Last updated time': 'Plan Güncelleme',
                'Will remove stored quick plan information': 'Tüm hızlı sulama planları devre dışı bırakılacak.',
                'Are you sure?': 'Emin misiniz?',
                'Will remove stored per day information': 'Günler için ayrı ayrı düzenlenmiş planları kaybedeceksiniz',
                'There are unsaved changes to the watering schedule': 'Kaydetmediğiniz değişiklikler var.',
                'Select from quick plan templates or create your own.': 'Hızlı plan taslaklarından seçebilirsiniz. Dilerseniz bu park için yeni hızlı plan yaratabilirsiniz.',
                'Create quick plan templates': 'Hızlı plan taslağı oluşturun.',
                'Each zone will be watered for selected number of minutes on selected days': 'Her bölge seçtiğiniz günlerde ayarladığınız süre kadar sulanacak.',
                'Start at': 'Başlangıç',
                'Water for': 'Sulama süresi',
                'Create': 'Kaydet',
                'Loading schedule data': 'Sulama planları yükleniyor',
                'technical_admin': 'Teknik Yönetici',
                'regional_manager': 'Bölge Yöneticisi',
                'field_manager': 'Park Yöneticisi',
                'day': 'Gün',
                'hour': 'Saat',
                'minute': 'Dakika',
                'Total': 'Toplam',
                'Quick': 'Kolay',
                'Advanced': 'Detaylı plan',
                'Common': 'Tek gün',
                'Per day': 'Tüm hafta',
                'Save': 'Kaydet',
                'Mo': 'Pt',
                'Tu': 'Sa',
                'We': 'Ça',
                'Th': 'Pe',
                'Fr': 'Cu',
                'Sa': 'Ct',
                'Su': 'Pa',
                'Name': 'İsim Soyisim',
                'Filter gardens by tags': 'Etiket ile filtrele',
                'Sun optimization': 'Sıcak',
                'Rain optimization': 'Yağmur',
                'Wind optimization': 'Rüzgar',
                'Smart watering enabled': 'Akıllı sulama aktif',
                'Something went wrong while enabling smart watering': 'Akıllı sulama ayarlarında bir sorun oluştu',
                'Yes': 'Evet',
                'No': 'Hayır',
                'Test started': 'Test başlatıldı',
                'Test stopped': 'Test durduruldu',
                'Gardens assigned successfully': 'İşlem başarılı',
                'Check the information you entered': 'Girdiğiniz bilgileri kontrol edin',
                'Faulty phone number': 'Hatalı telefon numarası',
                'View': 'Parkı göster',
                'Unassign': 'Cihazı parktan çıkart',
                'Stop': 'Durdur',
                'Start test': 'Başlat',
                'Successfully removed user': 'Kullanıcı başarıyla silindi',
                'Garden is in test mode': 'Bahçe bakım moduna alındı.',
                'Test mode': 'Bakım modu',
                'Test zone watering': 'Bölge sulama testi',
                'Device mode': 'Cihaz modu',
                'TEST': 'Bakım',
                'PSM': 'Uyku',
                'NORMAL': 'Erişilebilir',
                'User created successfully': 'İşlem başarılı',
                'Invitation has been sent to the user': 'Kullanıcı sisteme eklendi ve davet gönderildi',
                'Delete the user': 'Kullanıcıyı sil',
                'Changes saved successfully': 'Değişiklikler başarıyla kaydedildi.',
                'Something went wrong while saving changes': 'Kayıt esnasında bir sorun oluştu.',
                'Test state changed successfully': 'Bakım modu aktif',
                'Failed to change test state': 'Bakım moduna geçilemedi',
                'Device unassigned successfully': 'Cihaz bahçeden çıkarıldı',
                'Failed to unassign device': 'Cihaz bahçeden çıkarılamadı',
                'Failed to send test command': 'Test komutu gönderilemedi',
                'Device assignments successful': 'Cihaz bahçeye başarıyla eklendi',
                'devices assigned': 'Cihazlar eklendi',
                'Something went wrong': 'Bir sorun oluştu',
                'While assigning devices': 'Cihazlar eklenirken',
                'Device removal successful': 'Cihazlar kaldırma başarılı',
                'devices removed from garden': 'Bahçeden kaldırılan cihazlar',
                'While removing devices': 'Cihazları çıkarırken',
                'Tag created successfully': 'Tag başarıyla oluşturuldu',
                'While assigning gardens': 'Bahçeler atarken',
                'Gardens removal successful': 'Bahçeler kaldırma başarılı',
                'gardens removed from  selected tags': 'Bahçeler kaldırıldı',
                'While removing gardens': 'Bahçeleri çıkarırken',
                'User creation failed': 'Kullanıcı oluşturma başarısız oldu',
                'Failed to remove user': 'Kullanıcı kaldırılamadı',
                'Zoom level': 'Dakika',
                'Last online': 'Son bağlantı',
                'Garden ID and Name is required': "Park ismi ve ID'si gereklidir.",
                'Map area polygon is required': 'Lütfen haritadan parkı işaretleyin',
                'Test all valves (5 mins)': '5 dakika sıralı test',
                'Heat': 'Sıcaklık',
                'Rain probability': 'Hassasiyet',
                'Wind speed': 'Rüzgar',
                'Delete the garden': 'Bahçeyi sil',
                'Are you sure you want to remove this garden?': 'Are you sure you want to remove this garden?',
                'Water meter enabled': 'Akıllı Su Sayacı',
                'Watering': 'Su Tüketimi',
                'Add time': 'Ekle',
                'Signal': 'Sinyal',
                'Battery': 'Pil',
                'Create device': 'Cihaz ekleme',
                'Update device': 'Cihaz güncelleme',
                'Delete device': 'Cihaz silme',
                'Update device location': 'Cihaz lokasyon güncelleme',
                'Create garden': 'Park ekleme',
                'Update garden': 'Park güncelleme',
                'Assign device': 'Parka cihaz ekleme',
                'Remove device': 'Parktan cihaz çıkartma',
                'Commit scheduler plans': 'Detaylı plan ekleme',
                'Assign user': 'Parka kullanıcı ekleme',
                'Unassign user': 'Parktan kullanıcı çıkartma',
                'Tag garden': 'Parka etiket ekleme',
                'Untag garden': 'Parktan etiket çıkartma',
                'Activate template': 'Hızlı plan seçimi',
                'Create plan': 'Plan ekleme',
                'Delete plan': 'Plan silme',
                'Test watering': 'Test sulaması',
                'Create quick plan template': 'Hızlı plan ekleme',
                'Delete quick plan template': 'Hızlı plan silme',
                'Create tag': 'Etiket yaratma',
                'Delete tag': 'Etiket silme',
                'Battery Saver': 'Pil Tasarruf',
                'Normal': 'Normal',
                'Real Time': 'Canlı',
                'mode activated': 'modu aktif',
                'mode activation failed': 'moduna geçilemedi',
                'No watering SMS': 'Sulama yapılmadığında',
                'Low watering SMS': 'Su miktarı değiştiğinde',
                'Notification SMS status': 'SMS Bilgilendirme',
                'Low battery SMS': 'Düşük pil bildirimi',
                'Will remove stored advanced plan information': 'Geçiş yaptığınızda detaylı planlarınız iptal olacak.',
                'Update watering status for multiple parks': 'Birden fazla park için sulama durumunu değiştirme',
                'Update watering status': 'Sulama durumunu değiştir',
                'Created on': 'Giriş tarihi',
                'Logout': 'Çıkış yap',
                'Failed to update some garden statuses': 'Bazı bahçelerin durumu güncellenemedi',
                'Please try again': 'Lütfen tekrar deneyin',
        }
}

const t = (text) => {
        if (!translations.tr[text]) return text;

        return translations.tr[text]
}

export default t
