import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoginService } from "../../shared/services/LoginService";

export const initialState = {
  authLoading: false,
  loginResponse: null,
  authHasErrors: false,
  verifyResponse: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authLogin: (state) => {
      state.authLoading = true;
    },
    authLoginSuccess: (state, { payload }) => {
      state.authLoading = false;
      state.authHasErrors = false;
      state.loginResponse = payload;
    },
    authLoginFailure: (state) => {
      state.authLoading = false;
      state.authHasErrors = true;
    },
    authVerify: (state) => {
      state.authLoading = true;
    },
    authVerifySuccess: (state, { payload }) => {
      state.authLoading = false;
      state.authHasErrors = false;
      state.verifyResponse = payload;
    },
    authVerifyFailure: (state) => {
      state.authLoading = false;
      state.authHasErrors = true;
    },
  },
});

export const {
  authLogin,
  authLoginFailure,
  authLoginSuccess,
  authVerify,
  authVerifySuccess,
  authVerifyFailure,
} = authSlice.actions;

export const authSelector = (state) => state.auth;

export default authSlice.reducer;

export const inquireLogin = createAsyncThunk(
  "AuthLogin",
  (request, thunkAPI) => {
    thunkAPI.dispatch(authLogin());
    return LoginService.login(request)
      .then((response) => {
        return response?.data
          ? thunkAPI.dispatch(authLoginSuccess(response?.data || null))
          : thunkAPI.dispatch(authLoginFailure());
      })
      .catch((err) => thunkAPI.dispatch(authLoginFailure()));
  }
);

export const inquireVerifyCode = createAsyncThunk(
  "AuthVerify",
  (request, thunkAPI) => {
    thunkAPI.dispatch(authVerify());
    return LoginService.verify(request)
      .then((response) => {
        return response?.data
          ? thunkAPI.dispatch(authVerifySuccess(response?.data || null))
          : thunkAPI.dispatch(authVerifyFailure());
      })
      .catch((err) => thunkAPI.dispatch(authLoginFailure()));
  }
);
