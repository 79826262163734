import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

const HeaderTitleArea = (props) => {
  const navigate = useNavigate();
  const toDashboard = () => { navigate("/control-center/dashboard"); }
  return (
    <div onClick={toDashboard} className="text-white text-xl font-light cursor-pointer hidden lg:block">{props.headerTitle}</div>
  );
};

HeaderTitleArea.propTypes = {
  headerTitle: PropTypes.string,
};

export default HeaderTitleArea;
