import { useEffect, useState } from "react"
import tr from '../i18n'

const ActiveDaySelection = (props) => {

    useEffect(() => {
        if (!props.selectedDays) return;

        setDays(prev => prev.map(d => ({
            ...d,
            selected: props.selectedDays.includes(d.id)
        })))
    }, [props.selectedDays])

    const [days, setDays] = useState([
        {
            id: 1,
            name: tr("Mo"),
            selected: true
        },
        {
            id: 2,
            name: tr("Tu"),
            selected: true
        },
        {
            id: 3,
            name: tr("We"),
            selected: true
        },
        {
            id: 4,
            name: tr("Th"),
            selected: true
        },
        {
            id: 5,
            name: tr("Fr"),
            selected: true
        },
        {
            id: 6,
            name: tr("Sa"),
            selected: true
        },
        {
            id: 0,
            name: tr("Su"),
            selected: true
        },

    ])

    const handleDayClick = (day) => {
        const sDays = days.map(d => {
            if (d.id === day.id) {
                return {
                    ...d,
                    selected: !d.selected
                }
            }
            return d
        })

        props.onChange(sDays.filter(d => d.selected).map(d => d.id))
        setDays(sDays)
    }

    return <>
        <div className="flex items-center gap-2 h-8">
            {days.map(d => <div onClick={() => handleDayClick(d)} className={`flex items-center justify-center w-7 h-7 rounded-full select-none ${d.selected ? 'bg-green-brand' : 'bg-gray-500'} text-white cursor-pointer`}>{d.name}</div>)}
        </div>
    </>
}

export default ActiveDaySelection;