
import { useState } from 'react'
import { CheckOutlined } from '@ant-design/icons'
import PropTypes from "prop-types";

const CheckSpot = (props) => {
    const [checked, setChecked] = useState(props.checked)

    // useEffect(() => {
    //     setChecked()
    // }, [])

    const handleClick = (e) => {
        e.stopPropagation()
        if (props.onChange) props.onChange(!checked);

        setChecked(prev => !prev)
    }

    return <div onClick={handleClick} className={`flex rounded-full bg-green-brand w-5 h-5 items-center justify-center text-white border border-green-brand ${!checked && "bg-opacity-0"}`}>{checked && < CheckOutlined />}</div>
}

CheckSpot.propTypes = {
    onChange: PropTypes.func,
    checked: PropTypes.bool,
}

export default CheckSpot