import React from "react";
import { useContext } from 'react'
import { UserOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { AppContext } from '../AppContext'


const HeaderProfileInfo = (props) => {
  const { user } = useContext(AppContext)

  return (
    <div className="py-2 px-3 rounded-md bg-white bg-opacity-25 flex flex-row relative items-center">
      {props.user && (
        <div className="text-white text-right">
          {user && <div className="m-0">{user.name || user.phone || user.email}</div>}
          <div
            className="text-xs font-light hover:underline cursor-pointer text-white hover:text-white m-0"
            onClick={props.onLogout}
          >
            {props.logoutLabel}
          </div>
        </div>
      )}
      <div className="rounded-full h-8 w-8 b ml-3 flex items-center justify-center border border-white text-white bg-white bg-opacity-25">
        {/* <div className="rounded-full bg-blue-600 text-xs px-2 py-1 absolute -top-3 -right-3 border">
          {props.notificationCount}
        </div> */}
        <UserOutlined />
      </div>
      {false && (
        <div className="flex flex-col text-white bg-white bg-opacity-25 rounded-sm p-3 absolute right-0 top-11 z-10 w-full text-right">
          <div className="p-1 hover:underline">Profil</div>
          <div className="p-1 hover:underline">Güvenli Çıkış</div>
        </div>
      )}
    </div>
  );
};

HeaderProfileInfo.propTypes = {
  user: PropTypes.object,
  onLogout: PropTypes.func,
  logoutLabel: PropTypes.string,
  notificationCount: PropTypes.number,
};

export default HeaderProfileInfo;
