import L from 'leaflet';
import pin from '../assets/pin.svg'
import pinBL from '../assets/pin-bl.svg'
import deadPin from '../assets/dead-pin.svg'

const activeDeviceIcon = new L.Icon({
    iconUrl: pin,
    iconRetinaUrl: pin,
    iconAnchor: null,
    popupAnchor: [0, 0],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(12, 12),
    className: ' animate-pulse'
});

const devicePulsingLabeledDivIcon = ({ color, label }) => new L.DivIcon({
    html: `<div class="flex bg-white bg-opacity-90 align-baseline items-center rounded-full py-2 px-1 shadow-sm">
    <div class=" h-2 w-2 rounded-full  animate-ping fill-current ${color}"></div>
    <div class=" relative h-2 w-2 -left-2 rounded-full fill-current ${color}"></div>
    <div class="p-1 rounded-full flex align-middle justify-center text-xs text-gray-600">${label || ''}</div>
    </div>
    `,
    className: "flex"
});

const triangle = `<svg viewBox="0 0 140 123" xmlns="http://www.w3.org/2000/svg">
<path  d="M63.0718 3.99999C66.151 -1.33334 73.849 -1.33333 76.9282 4L138.416 110.5C141.495 115.833 137.646 122.5 131.488 122.5H8.51218C2.35378 122.5 -1.49521 115.833 1.584 110.5L63.0718 3.99999Z"/>
</svg>`

const square = `<svg viewBox="0 0 125 125" xmlns="http://www.w3.org/2000/svg">
<rect width="125" height="125" rx="8" />
</svg>`

const circle = `<svg viewBox="0 0 126 126" xmlns="http://www.w3.org/2000/svg">
<circle cx="63" cy="63" r="63"/>
</svg>`

const deviceModelPulsingPin = ({ alive, model }) => {
    let color = 'text-blue-500'
    let icon = triangle

    switch (alive) {
        case 'alive':
            switch (model) {
                case 'ELS':
                    color = 'text-yellow-400'
                    break;

                default:
                    color = 'text-blue-500'
                    break;
            }
            break;

        case 'dead':
            switch (model) {
                case 'BL':
                    color = 'text-yellow-400'
                    break;

                default:
                    color = 'text-red-500'
                    break;
            }
            break;

        default:
            switch (model) {
                case 'BL':
                    color = 'text-yellow-400'
                    break;

                case 'ELS':
                    color = 'text-yellow-400'
                    break;

                default:
                    color = 'text-blue-500'
                    break;
            }
            break;
    }

    switch (model) {
        case 'BL':
            icon = triangle
            break;

        case 'EL':
            icon = square
            break;

        case 'ELS':
            icon = square
            break;

        default:
            icon = circle
            break;
    }

    return new L.DivIcon({
        html: `<div class="flex align-baseline items-center">
            <div class=" h-3 w-3 rounded-full  animate-ping fill-current ${color}">${icon}</div>
            <div class=" relative h-3 w-3 -left-3 rounded-full fill-current ${color}">
            ${icon}
            </div>
        </div>`,
        className: "flex"
    })
};

const blDeviceIcon = new L.Icon({
    iconUrl: pinBL,
    iconRetinaUrl: pinBL,
    iconAnchor: null,
    popupAnchor: [0, 0],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(12, 12),
    className: ' animate-pulse'
});

const deadDeviceIcon = new L.Icon({
    iconUrl: deadPin,
    iconRetinaUrl: deadPin,
    iconAnchor: null,
    popupAnchor: [0, 0],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(8, 8),
    className: ''
}
);

export {
    devicePulsingLabeledDivIcon,
    deviceModelPulsingPin,
    activeDeviceIcon,
    deadDeviceIcon,
    blDeviceIcon
};