import { useEffect, useState, useContext } from "react";
import { Avatar, Input, notification, Select, Switch, InputNumber, Tag } from "antd";
import { useNavigate, useParams } from 'react-router-dom'
import {
    SearchOutlined,
    SelectOutlined,
    ArrowLeftOutlined
} from "@ant-design/icons";
import { RiZzzFill } from "react-icons/ri";
import { CiEdit } from "react-icons/ci";
import { BiCheck, BiBluetooth } from "react-icons/bi";

import { GrFormClose } from "react-icons/gr"
import axios from "axios";
import { transformTurkish } from "../utils/RegExUtility"
import useWindowSize from "../utils/WindowSize"
import { AppContext } from '../AppContext'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { MapContainer, TileLayer, useMap, Marker, useMapEvents, GeoJSON } from "react-leaflet";
import { MAP_ATTRIBUTION_URL, BASE_MAPS, GeoJSON_STYLE } from "../utils/MapUtils";
import { calculateBatteryLevel, getSignalStrengthForBars } from '../utils/DeviceUtils'
import { BiBattery } from "react-icons/bi";
import { hasRoles, ROLES } from '../utils/AccessLevels'
import tr from '../i18n'
import dayjs from "dayjs";
import SignalStrengthBars from "../components/SignalStrengthBars";
import ManagePlans from "../components/ManagePlans";
import InfoLabel from "../components/InfoLable";
const { Option } = Select

const GardenAccess = () => {
    const [userSearchQuery, setUserSearchQuery] = useState("")
    const [selectedDevice, setSelectedDevice] = useState(null)
    const [selectedTags, setSelectedTags] = useState([])
    const { role, gardens, fetchAllGardens } = useContext(AppContext);
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const { width } = useWindowSize()
    const { id: deviceId } = useParams()

    useEffect(() => {
        fetchAllGardens()
    }, [])

    const inventory = useQuery(["Inventory"], async () => {
        const res = await axios.get(process.env.REACT_APP_PLANNER_API_END_POINT + `/inventory?serial=${selectedDevice.deviceSerial}`)
        return res.data.devices
    }, { enabled: !!selectedDevice });

    /**
     * Garden devices
     */
    const devices = useQuery(["Devices"], async () => {
        const res = await axios.get(process.env.REACT_APP_PLANNER_API_END_POINT + "/garden/device/all")
        return res.data.devices
    }, { enabled: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if ((!deviceId || deviceId === '0' || !devices.data) && !selectedDevice) return;

        const selectedId = (selectedDevice && selectedDevice._id) || deviceId

        const [currentDevice] = devices.data.filter(d => d._id === selectedId)
        setSelectedDevice(currentDevice)


    }, [deviceId, devices.data])

    const deviceGarden = useQuery(["DeviceGarden", selectedDevice], async () => {
        if (!selectedDevice) return;

        const res = await axios.get(process.env.REACT_APP_PLANNER_API_END_POINT + "/garden/device/" + selectedDevice._id)

        console.log('deviceGarden', res);
        return res.data.garden
    }, { enabled: !!devices.data });

    const onDeviceClick = (device) => {
        setSelectedDevice(device)
    }

    const onUserSearch = (e) => {
        console.log(e.target.value);
        const value = e.target.value
        setUserSearchQuery(value)
    }

    const getFilteredDevices = () => {
        if (!devices.data) return [];

        let filteredDevices = devices.data
        if (userSearchQuery && userSearchQuery.trim().length > 1) {
            filteredDevices = filteredDevices.filter((d) => RegExp(`.*${transformTurkish(userSearchQuery.trim())}.*`, "gi").test(
                transformTurkish(d?.Device?.name) + d.deviceSerial + d.sid
            ))
        }

        if (selectedTags.length > 0) {
            filteredDevices = filteredDevices.filter((p) => p.tags && selectedTags.every(t => p.tags.includes(t)))
        }

        return filteredDevices;
    }

    // device status
    const getStatusIndicator = (status) => {
        if (status === 'alive') {
            return <div className="relative p-1 h-0 rounded-full bg-blue-500 mr-2">
                <div className=" absolute p-1 top-0 left-0 rounded-full bg-blue-500 animate-ping"></div>
            </div>
        }

        if (status === 'dead') {
            return <div className="relative p-1 h-0 rounded-full bg-red-500 mr-2">
                <div className=" absolute p-1 top-0 left-0 rounded-full bg-red-500 "></div>
            </div>
        }

        return <div className="relative p-1 h-0 rounded-full bg-gray-500 mr-2">
            <div className=" absolute p-1 top-0 left-0 rounded-full bg-gray-500 "></div>
        </div>
    }

    const getPin = (device) => {
        if (!device.Device) return [0, 0];

        return [device.Device.lat || 0, device.Device.lng || 0]
    }

    const setPin = (lat, lng) => {
        if (!selectedDevice) return;

        setSelectedDevice(p => {
            p.Device.lat = lat
            p.Device.lng = lng
            return p
        })
    }

    const unassignDevice = useMutation(() => {
        return axios.delete(process.env.REACT_APP_PLANNER_API_END_POINT + "/garden/device/", {
            data: {
                gardenId: deviceGarden.data.id, selectedDevice,
                deviceIds: [selectedDevice._id]
            }
        })
    }, {
        onSuccess: () => {
            notification.success({
                message: tr('Device unassigned successfully'),
            })
            queryClient.invalidateQueries('DeviceGarden')
        },
        onError: () => {
            notification.error({
                message: tr('Failed to unassign device'),
            })
        }
    })

    const [ongoingTests, setOngoingTests] = useState([])
    const [isTestingAll, setIsTestingAll] = useState(false)
    const [isTestingCustom, setIsTestingCustom] = useState(false)
    const [manualDurations, setManualDurations] = useState([0, 0, 0, 0, 0, 0, 0, 0])

    const testWatering = useMutation(({ valve, command, durations = [] }) => {
        return axios.post(process.env.REACT_APP_PLANNER_API_END_POINT + "/plan/test/watering", {
            deviceId: selectedDevice._id,
            valve,
            command,
            durations,
        })
    }, {
        onSuccess: (data, vars, ctx) => {
            notification.success({
                message: (vars.command === 'start' || vars.command === 'startAll' || vars.command === 'startCustom') ? tr('Test started') : tr('Test stopped'),
            })
            console.log(data, vars, ctx);

            if (vars.command === 'start') {
                setOngoingTests(p => [vars.valve])
                setIsTestingAll(false)
                setIsTestingCustom(false)
            } else if (vars.command === 'startAll') {
                setOngoingTests([])
                setIsTestingAll(true)
                setIsTestingCustom(false)
            } else if (vars.command === 'startCustom') {
                setOngoingTests([])
                setIsTestingAll(false)
                setIsTestingCustom(true)
            } else {
                setOngoingTests([])
                setIsTestingAll(false)
                setIsTestingCustom(false)
            }
        },
        onError: () => {
            notification.error({
                message: tr('Failed to send test command'),
            })
        }
    })

    const getValves = (device) => {
        if (!(device?.Device?.valves)) return [];

        const valves = JSON.parse(device.Device.valves)
        return Object.values(valves)
    }

    const [valves, setValves] = useState([])

    useEffect(() => {
        const v = getValves(selectedDevice).map(v => {
            v.isValveInputVisible = false
            return v
        })
        setValves(v)
    }, [selectedDevice])

    const MODES = {
        TEST: 'TEST',
        PSM: 'PSM',
        NORMAL: 'NORMAL'
    }

    const isActiveMode = (mode, alive) => {
        return selectedDevice.mode.toUpperCase() === mode && alive === 'alive'
    }

    const updateWaterMeter = useMutation(({ deviceSerial, status, tickValue, connectedDevices }) => {
        return axios.put(
            `${process.env.REACT_APP_PLANNER_API_END_POINT}/device/water-meter`,
            {
                deviceSerial,
                waterMeter: {
                    status,
                    tickValue,
                    connectedDevices
                }
            }
        )
    }, {
        onSuccess: (data, vars, ctx) => {
            queryClient.invalidateQueries('Devices')

            notification.success({
                message: "Water meter updated successfully",
                description: '',
                placement: "topRight",
            });
        },
        onError: (e) => {
            notification.error({
                message: "Something went wrong!",
                placement: "topRight",
            });
        }
    })

    const deleteWaterMeterConnectedDevice = useMutation(({ deviceSerial, connectedDevices }) => {
        return axios.delete(
            `${process.env.REACT_APP_PLANNER_API_END_POINT}/device/water-meter/connected-devices`,
            {
                data: {
                    deviceSerial,
                    connectedDevices
                }
            }
        )
    }, {
        onSuccess: (data, vars, ctx) => {
            queryClient.invalidateQueries('Devices')

            notification.success({
                message: "Water meter updated successfully",
                description: '',
                placement: "topRight",
            });
        },
        onError: (e) => {
            notification.error({
                message: "Something went wrong!",
                placement: "topRight",
            });
        }
    })


    const handleActivateWaterMeter = (e) => {
        console.log(selectedDevice)

        updateWaterMeter.mutate({ deviceSerial: selectedDevice.deviceSerial, status: e === true ? 'active' : 'disabled' })
    }

    const handleSelectTickValue = (e) => {
        console.log(e);
        console.log(selectedDevice)
        updateWaterMeter.mutate({ deviceSerial: selectedDevice.deviceSerial, tickValue: e })
    }

    // setting device location
    const [deviceLocation, setDeviceLocation] = useState({ lng: 0, lat: 0 })
    const updateDeviceLocation = useMutation(({ deviceSerial, lat, lng }) => {
        console.log(lat, lng);
        console.log(selectedDevice.Device.lng, parseFloat(lng.toFixed(4)), selectedDevice.Device.lat, parseFloat(lat.toFixed(4)), (selectedDevice && selectedDevice.Device.lng === parseFloat(lng.toFixed(4)) && selectedDevice.Device.lat === parseFloat(lat.toFixed(4))));

        return axios.put(
            `${process.env.REACT_APP_PLANNER_API_END_POINT}/device/coordinates`,
            {
                deviceSerial,
                lat,
                lng,
            }
        )
    }, {
        onSuccess: (data, vars, ctx) => {
            if (selectedDevice && selectedDevice.Device.lng === parseFloat(vars.lng.toFixed(4)) && selectedDevice.Device.lat === parseFloat(vars.lat.toFixed(4))) return;

            queryClient.invalidateQueries('Devices')

            notification.success({
                message: "Başarılı bir şekilde güncellenen cihaz koordinatları",
                description: '',
                placement: "topRight",
            });
        },
        onError: (e) => {
            notification.error({
                message: "Bir şeyler yanlış gitti!",
                description: e.message,
                placement: "topRight",
            });
        }
    })

    const [pinEditable, setPinEditable] = useState(false)


    const toggleValveInputVisible = (i) => {
        setValves((prev) => {
            const newValves = prev.map((v) => ({ ...v }));

            return newValves.map((v, index) => {
                if (index === i) {
                    v.isValveInputVisible = !v.isValveInputVisible
                }
                return v
            })
        })
    }

    const setValveName = (i, value) => {
        setValves((prev) => {
            const newValves = prev.map((v) => ({ ...v }));
            return newValves.map((v, index) => {
                if (index === i) {
                    v.name = value
                }
                return v
            })
        })
    }

    const updateValveName = useMutation(({ deviceId, valveId, name, originalName }) => {
        return axios.put(
            `${process.env.REACT_APP_GARDENER_API_END_POINT}/device/${deviceId}/valve/${valveId}/name`,
            {
                name
            }
        )
    }, {
        onSuccess: (data, vars, ctx) => {
            queryClient.invalidateQueries('Devices')
            toggleValveInputVisible(vars.valveId - 1)

            notification.success({
                message: "Valve name updated successfully!",
                description: '',
                placement: "topRight",
            });
        },
        onError: (e, vars) => {
            // Immediately revert to original name in local state
            setValves(prev => prev.map((v, i) => {
                if (i === vars.valveId - 1) {
                    return { ...v, name: vars.originalName }
                }
                return v
            }))

            queryClient.invalidateQueries('Devices')
            toggleValveInputVisible(vars.valveId - 1)

            notification.error({
                message: "Something went wrong!",
                description: e.message,
                placement: "topRight",
            });
        }
    })

    const cancelEditingValveName = (i) => {
        toggleValveInputVisible(i)
        queryClient.invalidateQueries('Devices')
    }

    const updateValveState = useMutation(({ deviceId, valveId, state }) => {
        return axios.put(
            `${process.env.REACT_APP_GARDENER_API_END_POINT}/device/${deviceId}/valve/${valveId}/state`,
            {
                state: state === true ? 'on' : 'off'
            }
        )
    }, {
        onSuccess: (data, vars, ctx) => {
            queryClient.invalidateQueries('Devices')

            notification.success({
                message: "Valve state updated successfully!",
                description: '',
                placement: "topRight",
            });
        },
        onError: (e) => {
            notification.error({
                message: "Something went wrong!",
                description: e.message,
                placement: "topRight",
            });
        }
    })

    const isValveAvailable = (device, valveIndex) => {
        if (!device.valveCount) return true;

        return (valveIndex < device.valveCount)
    }

    // feature: 
    // water-meters filters on devices

    return <>
        <div className="bg-white border text-gray-800 rounded-md flex flex-row rounded-tl-none">
            {(!selectedDevice || (width > 768)) && <div className="flex flex-col w-full md:w-1/4" style={{ height: "calc(100vh - 200px)" }}>
                <div className="p-4 border-b relative">
                    <Input style={{ borderRadius: 5 }} onChange={onUserSearch} prefix={<SearchOutlined />} placeholder="Cihaz arama" allowClear />
                </div>
                {devices.isLoading && <><div className="p-2 animate-pulse flex flex-row">
                    <div className="bg-gray-200 h-5 w-5 mr-2 rounded-full"></div>
                    <div className="bg-gray-200 h-5 w-full rounded-md"></div>
                </div>
                    <div className="p-2 animate-pulse flex flex-row">
                        <div className="bg-gray-200 h-5 w-5 mr-2 rounded-full"></div>
                        <div className="bg-gray-200 h-5 w-full rounded-md"></div>
                    </div></>}
                <div className=" w-full h-full overflow-auto">
                    {devices.isSuccess && getFilteredDevices().map(device => <div onClick={() => onDeviceClick(device)} className="flex flex-row gap-4 items-center hover:bg-gray-100 p-4 border-b cursor-pointer">
                        <div className="">
                            {device.model === "BL" ? <div className=" flex items-center text-white text-xl justify-center bg-blue-500 rounded-full w-8 h-8"><BiBluetooth /></div> : <Avatar >{device?.Device?.id}</Avatar>}
                        </div>
                        <div className="">
                            <div className="relative font-bold flex items-center">{device.model === "BL" ? "" : getStatusIndicator(device.alive)}{device.model === "BL" ? `BLT ${device.sid.slice(-5)}` : device?.Device?.name}</div>
                            {device.model === "BL" ? <div className=" text-gray-500 text-xs">
                                {device?.Device?.name}
                            </div>
                                : <div className="text-xs flex items-center gap-3">
                                    {device.mode && device.mode.toUpperCase() === MODES.PSM && <div><RiZzzFill /></div>}
                                    <div className="flex items-center gap-1">
                                        <SignalStrengthBars value={getSignalStrengthForBars(device.signal)} size="small" />
                                    </div>
                                    <div className="flex items-center gap-1">
                                        <BiBattery /> {calculateBatteryLevel(device.battery, device.batteryOffset, device.model)}
                                    </div>
                                </div>}
                        </div>
                    </div>)}
                </div>
            </div>}
            {(selectedDevice || width > 768) && <div className=" border-l flex flex-col w-full">
                {selectedDevice && <div className="flex flex-row gap-4 items-center p-4 border-b w-full flex-wrap">
                    <button
                        onClick={() => {
                            setSelectedDevice(null)
                        }}
                        to="dashboard"
                        className="px-3 p-1 rounded-md w-max active flex items-center bg-gray-100 md:hidden"
                    >
                        <ArrowLeftOutlined />
                        <span className="ml-2">Geri</span>
                    </button>
                    <div className="">
                        <Avatar>{selectedDevice.Device.id}</Avatar>
                    </div>
                    <div className="border-r pr-4">
                        <div className="block font-bold text-lg">{selectedDevice?.Device?.name}</div>
                        <div className="text-xs flex items-center gap-1">
                            <div>{selectedDevice.address}</div>
                            <div className=" flex items-center gap-2">
                                {getStatusIndicator(selectedDevice.alive)}
                                {selectedDevice.lastOnlineAt && dayjs(selectedDevice.lastOnlineAt).format('DD/MM HH:mm:ss')}
                                <div className="flex items-center p-1 px-2 bg-gray-700 text-white rounded-md gap-3">
                                    <div className="flex items-center gap-1">
                                        <SignalStrengthBars value={getSignalStrengthForBars(selectedDevice.signal)} darkMode />
                                    </div>
                                    <div className="flex items-center gap-1">
                                        <BiBattery /> {calculateBatteryLevel(selectedDevice.battery, selectedDevice.batteryOffset, selectedDevice.model)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {deviceGarden.isSuccess && deviceGarden.data === null && <div className=" bg-gray-200 text-xs p-2 px-4 rounded-md">UNASSIGNED</div>}
                    {deviceGarden.isSuccess && deviceGarden.data && <div className="  text-sm px-4 p-2 rounded-md  flex flex-col border">
                        {/* <div className=" text-xs text-blue-500">Assigned to</div> */}
                        <div className="flex items-center gap-2">
                            {deviceGarden.data.id} - {deviceGarden.data.name}
                            {role !== ROLES.GARDEN_TESTER && (
                                <>
                                    <button className=" text-blue-500 flex items-center p-1 px-2 rounded-sm bg-gray-100 hover:bg-gray-200 cursor-pointer gap-1 text-xs" onClick={() => { navigate('/control-center/gardens/' + deviceGarden.data.id) }}>
                                        <SelectOutlined /> <div>{tr("View")}</div>
                                    </button>
                                    <button className=" text-red-500 text-xs p-1 px-2 bg-gray-100 hover:bg-gray-200 rounded-sm cursor-pointer" onClick={() => { unassignDevice.mutate() }}>{tr("Unassign")}</button>
                                </>
                            )}
                        </div>
                    </div>}

                </div>}
                <div className="flex gap-4 flex-wrap">
                    {!selectedDevice && <div className="flex p-4 border rounded-md  gap-4 m-4 mx-6">
                        <InfoLabel>Sol taraftaki listeden bir cihaz seçin </InfoLabel>
                    </div>}
                    {selectedDevice && <div className="w-full flex">
                        {role === ROLES.GARDEN_TESTER ? (
                            <div className="p-4 w-full">
                                <div className="flex gap-4 flex-wrap">
                                    <div className="flex flex-col gap-1">
                                        <InfoLabel>
                                            {tr("Test zone watering")}
                                            {!isTestingAll && <button className=" text-xs bg-gray-100 p-1 px-4 rounded-sm text-blue-500" onClick={() => testWatering.mutate({ command: 'startAll' })}>{tr("Test all valves (5 mins)")}</button>}
                                            {isTestingAll && <button className=" text-xs bg-gray-100 p-1 px-4 rounded-sm text-red-500" onClick={() => testWatering.mutate({ command: 'stop' })}>{tr("Stop")}</button>}
                                        </InfoLabel>
                                        <div className="grid grid-cols-4 border rounded-md p-2 px-4 gap-2">
                                            <div className=" text-xs text-gray-500">No</div>
                                            <div className=" text-xs text-gray-500 col-span-2">{tr("Zone")}</div>
                                            <div className=" text-xs text-gray-500">Test</div>
                                            {valves.map((v, i) => <>
                                                {isValveAvailable(selectedDevice, i) && <div key={i + '-index'}>{i + 1}</div>}
                                                {isValveAvailable(selectedDevice, i) && <div key={i + '-name'} className="col-span-2">{v.name}</div>}
                                                {isValveAvailable(selectedDevice, i) && <div key={i + '-action'}>
                                                    {!ongoingTests.includes(i) && <button className=" text-xs bg-gray-100 p-1 px-4 rounded-sm text-blue-500" onClick={() => testWatering.mutate({ valve: i, command: 'start' })}>{tr("Start test")}</button>}
                                                    {ongoingTests.includes(i) && <button className=" text-xs bg-gray-100 p-1 px-4 rounded-sm text-red-500" onClick={() => testWatering.mutate({ valve: i, command: 'stop' })}>{tr("Stop")}</button>}
                                                </div>}
                                            </>)}
                                        </div>
                                        <InfoLabel> {tr("Test Sulaması")}</InfoLabel>
                                        <div className="flex flex-col border rounded-md p-2 px-4 gap-2">
                                            <div className="flex items-center flex-wrap gap-1">
                                                {valves.map((v, i) =>
                                                    <>
                                                        {i !== 0 && <div>-</div>} <div className="flex flex-col text-xs">
                                                            <InputNumber min={0} style={{ width: 45 }} defaultValue={0} onChange={(v) => setManualDurations(p => { p[i] = v; return p })} />
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                            <div>
                                                {!isTestingCustom && <button className=" text-xs bg-gray-100 p-1 px-4 rounded-sm text-blue-500" onClick={() => testWatering.mutate({ command: 'startCustom', durations: manualDurations })}>{tr("Start test")}</button>}
                                                {isTestingCustom && <button className=" text-xs bg-gray-100 p-1 px-4 rounded-sm text-red-500" onClick={() => testWatering.mutate({ command: 'stop' })}>{tr("Stop")}</button>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className=" w-1/2 overflow-hidden relative" >
                                    <MapContainer center={getPin(selectedDevice)} zoom={17} scrollWheelZoom={true} style={{ height: '100%' }} >
                                        <TileLayer attribution={MAP_ATTRIBUTION_URL} url={BASE_MAPS[2].value} opacity={1} />
                                        <TileLayer attribution={MAP_ATTRIBUTION_URL} url={BASE_MAPS[6].value} opacity={0.9} />
                                        {gardens && gardens.map(g => {

                                            if (!g.geoJson) return '';

                                            return <GeoJSON
                                                style={(f) => (GeoJSON_STYLE)}
                                                key={g.geoJson.properties.MAHAL_ID}
                                                data={g.geoJson}
                                            >

                                            </GeoJSON>
                                        })}
                                        <MapDetails pin={getPin(selectedDevice)} onNewPin={(e) => { if (!pinEditable) return; updateDeviceLocation.mutate({ deviceSerial: selectedDevice.deviceSerial, lat: e.lat, lng: e.lng }) }} />
                                    </MapContainer>
                                    <div className="flex flex-col gap-1 absolute bottom-4 left-2" style={{ zIndex: 999 }}>
                                        <InfoLabel> {tr("Cihaz konumu")}</InfoLabel>
                                        <div className="flex text-xs text-gray-700 gap-1 items-center">
                                            <div className="flex ">
                                                <div className=" bg-gray-200 rounded-md rounded-r-none p-1 px-3 flex items-center">enlem </div>
                                                <InputNumber disabled onChange={(e) => { setDeviceLocation((p) => { p.lat = e; return p }) }} value={selectedDevice.Device.lat} />
                                            </div>
                                            <div className="flex ">
                                                <div className=" bg-gray-200 rounded-md rounded-r-none p-1 px-3 flex items-center">boylam</div>
                                                <InputNumber disabled onChange={(e) => { setDeviceLocation((p) => { p.lng = e; return p }) }} value={selectedDevice.Device.lng} />
                                            </div>
                                            <Switch unCheckedChildren="Editable" checkedChildren="Editable" onChange={(e) => setPinEditable(e)} />
                                        </div>
                                    </div>
                                </div>
                                <div className=" p-4 w-1/2">
                                    <div className="flex gap-4 flex-wrap">
                                        <div className="flex flex-col gap-1">
                                            <InfoLabel>
                                                {tr("Test zone watering")}
                                                {!isTestingAll && <button className=" text-xs bg-gray-100 p-1 px-4 rounded-sm text-blue-500" onClick={() => testWatering.mutate({ command: 'startAll' })}>{tr("Test all valves (5 mins)")}</button>}
                                                {isTestingAll && <button className=" text-xs bg-gray-100 p-1 px-4 rounded-sm text-red-500" onClick={() => testWatering.mutate({ command: 'stop' })}>{tr("Stop")}</button>}
                                            </InfoLabel>
                                            <div className="grid grid-cols-4 border rounded-md p-2 px-4 gap-2">
                                                <div className=" text-xs text-gray-500">No</div>
                                                <div className=" text-xs text-gray-500 col-span-2">{tr("Zone")}</div>
                                                <div className=" text-xs text-gray-500">Test</div>
                                                {valves.map((v, i) => <>
                                                    {isValveAvailable(selectedDevice, i) && <div key={i + '-index'}>{i + 1} <Switch size="small" checked={v.state === 'on'} onChange={(v) => updateValveState.mutate({ deviceId: selectedDevice.Device.id, valveId: i + 1, state: v })} /></div>}
                                                    {isValveAvailable(selectedDevice, i) && <div key={i + '-name'} className=" col-span-2 flex gap-1 group">
                                                        {!v.isValveInputVisible && v.name} {v.isValveInputVisible && <Input value={v.name} onChange={(e) => setValveName(i, e.target.value)} className=" w-auto" />}
                                                        {v.isValveInputVisible && <>
                                                            <button onClick={() => updateValveName.mutate({
                                                                deviceId: selectedDevice.Device.id,
                                                                valveId: i + 1,
                                                                name: v.name,
                                                                originalName: getValves(selectedDevice)[i].name
                                                            })} className="p-2 rounded-md bg-gray-100"><BiCheck /></button>
                                                            <button onClick={() => cancelEditingValveName(i)} className="p-2 rounded-md bg-gray-100"><GrFormClose /></button>
                                                        </>}
                                                        {!v.isValveInputVisible && <button className="p-2 rounded-md bg-gray-100 group-hover:visible invisible" onClick={() => toggleValveInputVisible(i)}><CiEdit /></button>}
                                                    </div>}
                                                    {isValveAvailable(selectedDevice, i) && <div key={i + '-action'}>
                                                        {!ongoingTests.includes(i) && <button className=" text-xs bg-gray-100 p-1 px-4 rounded-sm text-blue-500" onClick={() => testWatering.mutate({ valve: i, command: 'start' })}>{tr("Start test")}</button>}
                                                        {ongoingTests.includes(i) && <button className=" text-xs bg-gray-100 p-1 px-4 rounded-sm text-red-500" onClick={() => testWatering.mutate({ valve: i, command: 'stop' })}>{tr("Stop")}</button>}
                                                    </div>}

                                                </>)}
                                            </div>
                                            <InfoLabel> {tr("Test Sulaması")}</InfoLabel>
                                            <div className="flex flex-col border rounded-md p-2 px-4 gap-2">
                                                <div className="flex items-center flex-wrap gap-1">
                                                    {valves.map((v, i) =>
                                                        <>
                                                            {i !== 0 && <div>-</div>} <div className="flex flex-col text-xs">
                                                                <InputNumber min={0} style={{ width: 45 }} defaultValue={0} onChange={(v) => setManualDurations(p => { p[i] = v; return p })} />
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                                <div>
                                                    {!isTestingCustom && <button className=" text-xs bg-gray-100 p-1 px-4 rounded-sm text-blue-500" onClick={() => testWatering.mutate({ command: 'startCustom', durations: manualDurations })}>{tr("Start test")}</button>}
                                                    {isTestingCustom && <button className=" text-xs bg-gray-100 p-1 px-4 rounded-sm text-red-500" onClick={() => testWatering.mutate({ command: 'stop' })}>{tr("Stop")}</button>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex gap-4">

                                            {selectedDevice.mode && <div className="flex flex-col gap-1">
                                                <InfoLabel>
                                                    {tr("Device mode")}
                                                </InfoLabel>
                                                <div className="flex">
                                                    <div className={` p-1 px-4  rounded-sm text-xs text-white bg-gray-300  font-bold rounded-r-none ${isActiveMode(MODES.TEST, selectedDevice.alive) ? 'bg-green-500' : ''}`}>{tr(MODES.TEST)}</div>
                                                    <div className={` p-1 px-4  text-xs text-white bg-gray-300  font-bold rounded-none  ${isActiveMode(MODES.PSM, selectedDevice.alive) ? 'bg-gray-600' : ''}`}>{tr(MODES.PSM)}</div>
                                                    <div className={`p-1 px-4  rounded-sm text-xs text-white bg-gray-300  font-bold rounded-l-none ${isActiveMode(MODES.NORMAL, selectedDevice.alive) ? 'bg-blue-500' : ''}`}>{tr(MODES.NORMAL)}</div>
                                                </div>
                                            </div>}
                                            {selectedDevice.lastOnlineAt && <div className="flex flex-col gap-1">
                                                <InfoLabel>{tr("Last online")}</InfoLabel>
                                                <div className="flex font-bold text-gray-700">
                                                    {dayjs(selectedDevice.lastOnlineAt).format('DD/MM HH:mm:ss')}
                                                </div>
                                            </div>}
                                            <div className=" flex flex-col gap-2">
                                                <InfoLabel>{tr("Water meter enabled")}</InfoLabel>
                                                <div className="flex flex-col gap-4 p-4 border rounded-md">
                                                    <div className="flex font-bold text-gray-700 gap-4 items-center">
                                                        {<Switch
                                                            checked={selectedDevice?.waterMeter?.status === 'active'}
                                                            disabled={!hasRoles([ROLES.SUPER_ADMIN, ROLES.TECHNICAL_ADMIN, ROLES.PRO_USER], role)}
                                                            onChange={handleActivateWaterMeter}
                                                        />}
                                                        {selectedDevice?.waterMeter?.status === 'active' && <Select
                                                            disabled={!hasRoles([ROLES.SUPER_ADMIN, ROLES.TECHNICAL_ADMIN, ROLES.PRO_USER], role)}
                                                            style={{ minWidth: 100 }}
                                                            value={selectedDevice?.waterMeter?.tickValue}
                                                            onChange={handleSelectTickValue}
                                                        >
                                                            <Option value={1}>1 litre</Option>
                                                            <Option value={10}>10 litres</Option>
                                                            <Option value={100}>100 litres</Option>
                                                        </Select>}
                                                    </div>
                                                    {selectedDevice?.waterMeter?.status === 'active' && devices.data && <div className=" flex flex-col gap-3">
                                                        <div className=" text-xs">Connected devices</div>
                                                        {selectedDevice.waterMeter && selectedDevice.waterMeter.connectedDevices
                                                            && selectedDevice.waterMeter.connectedDevices.length > 0 && <div className="flex gap-1 flex-wrap ">
                                                                {selectedDevice.waterMeter.connectedDevices
                                                                    .map((d, i) => <Tag
                                                                        key={d._id}
                                                                        closable={true}
                                                                        onClose={() => {
                                                                            console.log(d);
                                                                            deleteWaterMeterConnectedDevice.mutate({ deviceSerial: selectedDevice.deviceSerial, connectedDevices: [d._id] })
                                                                        }}>
                                                                        {d.name}
                                                                    </Tag>)}
                                                            </div>}
                                                        <Select showSearch placeholder="Search device"
                                                            filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                                            options={devices.data.map(d => ({ label: d?.Device?.name, value: d._id }))}
                                                            onChange={(v) => { console.log(v); updateWaterMeter.mutate({ deviceSerial: selectedDevice.deviceSerial, connectedDevices: [v] }) }}
                                                        >
                                                        </Select>
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="flex flex-col gap-1">
                                                <InfoLabel>{tr("Watering plans")}</InfoLabel>
                                                <ManagePlans device={selectedDevice} onUpdate={() => { queryClient.invalidateQueries('Devices') }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>}
                </div>
            </div>}
        </div >
    </>
}

const MapDetails = ({ pin, onNewPin }) => {
    const map = useMap()
    const mapEvents = useMapEvents({
        click: (e) => {
            onNewPin(e.latlng)
        }
    })

    const [deviceLocation, setDeviceLocation] = useState([0, 0])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        // check if pin has changed
        if (pin[0] === deviceLocation[0] && pin[1] === deviceLocation[1]) return;

        setDeviceLocation(pin)
        map.flyTo(pin, 16, { animate: true, duration: 1.2 })
    }, [pin])

    return <>
        <Marker position={pin}>
        </Marker>
    </>
}

export default GardenAccess