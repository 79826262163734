import React, { useEffect, useState } from "react";
import logo from "../assets/logo.svg";
import logoText from "../assets/logo-text.svg";
import PropTypes from "prop-types";
import HeaderLogoArea from "./HeaderLogoArea";
import HeaderTitleArea from "./HeaderTitleArea";
import HeaderProfileInfo from "./HeaderProfileInfo";

const Header = (props) => {
  const [settings, setSettings] = useState(null)
  useEffect(() => {
    let settings = localStorage.getItem('settings')
    if (settings) {
      setSettings(JSON.parse(settings))
    }
  }, [])
  return (
    <div>
      <div className="flex flex-row items-center justify-between pt-3 px-2 border-opacity-25">
        <HeaderLogoArea logo={settings?.logoUrl || process.env.REACT_APP_LOGO || logo} logoText={settings?.logoUrl || process.env.REACT_APP_LOGO ? null : logoText} />
        <HeaderTitleArea headerTitle={props.headerTitle} />
        <HeaderProfileInfo
          notificationCount={props.notificationCount}
          logoutLabel={props.logoutLabel}
          onLogout={props.onLogout}
          user={props.user}
        />
      </div>
    </div>
  );
};

Header.propTypes = {
  user: PropTypes.object,
  onLogout: PropTypes.func,
  headerTitle: PropTypes.string,
  notificationCount: PropTypes.number,
  logoutLabel: PropTypes.string,
};

export default Header;
