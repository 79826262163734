import React from "react";

const Footer = () => {
  return (
    <div className="flex flex-row justify-center items-center text-gray-800 z-10 py-10 mt-20 text-xs">
      © {new Date().getFullYear()} Grüngard. Tüm hakkı saklıdır.
    </div>
  );
};

export default Footer;
