import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../containers/ControlCenter.css";
import Header from "./Header";
import Footer from "./Footer";
import PropTypes from "prop-types";
import { AppContext } from '../AppContext';
import { useMutation } from "react-query";
import axios from "axios";
import { notification } from "antd";
import tr from '../i18n'

const AppContainer = (props) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const { setToken } = useContext(AppContext)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const token = localStorage.getItem("token");
    const userObj = localStorage.getItem("user");
    setUser(JSON.parse(userObj));

    if (!token) {
      navigate("/login");
    }
  }, []);

  const logoutUser = useMutation(() => {
    return axios.post(
      `${process.env.REACT_APP_GARDENER_API_END_POINT}/auth/logout`
    )
  }, {
    onSuccess: () => {
      notification.success({
        message: tr("User logged out successfully"),
      })
    },
    onError: (error) => {
      notification.error({
        message: tr("Something went wrong while logging out user"),
      })
    },
  })

  const onLogout = async () => {
    await logoutUser.mutateAsync()
    localStorage.removeItem("token");
    setToken(null)
    localStorage.removeItem("settings");
    localStorage.removeItem("user");
    navigate("/login");
  };

  return (
    <>
      <div className="flex flex-col">
        <div className="bg-gradient-to-r from-green-brand to-blue-mysterious">
          <div className="container mx-auto flex flex-col">
            <Header
              user={user}
              onLogout={onLogout}
              notificationCount={3}
              headerTitle="Merkezi Sulama Yönetim Sistemi"
              logoutLabel="Güvenli Çıkış"
            />
            <div className="h-44"></div>
          </div>
        </div>
        <div className="relative flex justify-center">
          <div className="absolute -top-44 container ">
            {props.children}
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

AppContainer.propTypes = {
  children: PropTypes.any,
};

export default AppContainer;
