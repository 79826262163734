import { GeoJSON, Popup, useMap } from "react-leaflet";
import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { GeoJSON_STYLE } from "../utils/MapUtils";

const MapDetail = (props) => {
  const map = useMap();
  const gjRef = useRef();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (gjRef.current) {
      const bounds = gjRef.current.getBounds();
      map.fitBounds(bounds);
    }
  }, [props.mapCenter]);

  return (
    <>
      {props.mapCenter && props.mapCenter.properties && (
        <GeoJSON
          style={(f) => (GeoJSON_STYLE)}
          ref={gjRef}
          key={props.mapCenter.properties.MAHAL_ID}
          data={props.mapCenter}
        >
          <Popup>
            {props.mapCenter.properties.MAHAL_ADI}
            <br />
            {props.mapCenter.properties.MALIKTIP}
          </Popup>
        </GeoJSON>
      )}
    </>
  );
};

MapDetail.propTypes = {
  mapCenter: PropTypes.object,
};

export default MapDetail;
