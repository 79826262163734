import { createContext, useEffect, useState } from "react";
import axios from "axios";
import { useQuery } from "react-query"

const AppContext = createContext({})

const AppContextProvider = (props) => {
    const [selectedGarden, setSelectedGarden] = useState(null);
    const [user, setUser] = useState(null);
    const [role, setRole] = useState(null);
    const [gardens, setGardens] = useState([]);
    const [loadingGardens, setLoadingGardens] = useState(false)
    const [token, setToken] = useState(null)
    const [notifications, setNotifications] = useState([])
    const [tenantSettings, setTenantSettings] = useState(null)

    const userRes = useQuery(['GetMyUser'], () => {
        return axios.get(process.env.REACT_APP_GARDENER_API_END_POINT + '/user/me')
    }, { enabled: !!token })

    const notificationsRes = useQuery(['GetNotifications'], () => {
        return axios.get(process.env.REACT_APP_PLANNER_API_END_POINT + '/tenant/notifications')
    }, { enabled: !!token, refetchInterval: 30000, refetchIntervalInBackground: true })

    useEffect(() => {
        if (!notificationsRes.data) return;

        setNotifications(notificationsRes.data.data.data)
    }, [notificationsRes.data])

    useEffect(() => {
        if (!userRes.data) return;

        setUser(userRes.data.data.data)
        setRole(userRes.data.data.data.role)

        let settings = localStorage.getItem('settings')
        if (settings) {
            settings = JSON.parse(settings)
            setTenantSettings(settings)
        }

        if (process.env.REACT_APP_FAVICON) {
            const favicon = document.getElementById('favicon')
            favicon.href = settings ? settings.faviconUrl : process.env.REACT_APP_FAVICON
        }

        if (process.env.REACT_APP_TITLE) {
            const title = document.querySelector('title')
            title.innerHTML = settings ? settings.title : process.env.REACT_APP_TITLE
        }
    }, [userRes.data])

    const fetchAllGardens = async ({ background = false, force = false } = {}) => {
        try {
            if (gardens.length > 0 && !force) { return }
            if (!background) {
                setLoadingGardens(true)
            }
            const res = await axios.get(process.env.REACT_APP_PLANNER_API_END_POINT + '/garden')
            setGardens(res.data.gardens)
        } catch (error) {
            console.log(error);
        } finally {
            setLoadingGardens(false)
        }
    }

    return <AppContext.Provider value={{ selectedGarden, setSelectedGarden, user, role, gardens, fetchAllGardens, loadingGardens, token, setToken, notifications, tenantSettings }}>
        {props.children}
    </AppContext.Provider>
}

export {
    AppContext,
    AppContextProvider
}